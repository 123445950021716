import React, { useEffect, useState } from "react";
import { Row, Col, Select, DatePicker } from "antd";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";

const { Option } = Select;
let i = 10;

const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");

if (checkStartOfMonth == 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const monthFormat = "YYYY-MM";
let initialAccountId;
let initialAccountIdAWS;
export default function SelectComponent({
  chooseCloud,
  selectMonth,
  selectTags,
  awsData,
  gcpData,
  azureData,
  setAWSServices,
  setGCPServices,
  setAzureServices,
  getGCPData,
  getAWSData,
  getAzureData,
}) {
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(initalMonth);
  const [cloudType, setCloudType] = useState("Azure");
  const [project, setProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [azureAccount, setAzureAccount] = useState("");
  const [azureAccountId, setAzureAccountId] = useState("");
  const [azureAccounts, setAzureAccounts] = useState([]);
  const [awsAccount, setAwsAccount] = useState("");
  const [awsAccountId, setAwsAccountId] = useState("");
  const [awsAccounts, setAwsAccounts] = useState([]);

  var initialProjectName = [];
  var initialAwsProjectName = [];

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0]?.account_name;
      setAzureAccount(initialProjectName);
      initialAccountId = initialAccount[0]?.account_id;
      setAzureAccountId(initialAccountId);
      setAzureAccounts(resp.data);
      setLoading(true);
      setData([]);
      if (cloudType === "Azure") {
        setLoading(true);
        getAzureData(month, initialAccountId, accessToken);
      }
      //  else if (cloudType === "AWS") {
      //   setLoading(true);
      //   getAWSData(month, awsAccountId, accessToken);
      // } else if (cloudType === "GCP") {
      //   setLoading(true);

      //   getGCPData(month, project, accessToken);
      // }
    })();
  }, [cloudType]);

  const handleMonthChange = async (date, dateString) => {
    setLoading(true);
    setMonth(dateString);
    selectMonth(dateString);
    getGCPData(dateString, project, "");
  };

  const handleAzureMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setMonth(dateString);
    selectMonth(dateString);
    getAzureData(dateString, azureAccountId, accessToken);
  };

  const handleAwsMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setMonth(dateString);
    selectMonth(dateString);
    getAWSData(dateString, awsAccountId, accessToken);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    chooseCloud(value);
    setCloudType(value);
    if (value == "GCP") {
      setLoading(true);
      let baseURL =
        "https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectlist";
      let url = await axios.get(baseURL, {
        headers: { Authorization: "Bearer " + accessToken },
      });
      console.log("response from gcp project list", url);
      initialProjectName = url.data[0].project_name;
      setProject(initialProjectName);
      setProjects(url.data);
      setLoading(true);
      getGCPData(month, url.data[0].project_name, accessToken);
    } else if (value == "Azure") {
      setLoading(true);
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0].account_name;
      setAzureAccount(initialProjectName);
      initialAccountId = initialAccount[0].account_id;
      setAzureAccountId(initialAccountId);
      setAzureAccounts(resp.data);
      setLoading(true);
      setData([]);
      getAzureData(month, initialAccountId, accessToken);
    } else if (value == "AWS") {
      setLoading(true);
      let initialAccountAws = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      console.log("awsaccountlist", resp.data);
      initialAccountAws = resp.data;
      initialAwsProjectName = initialAccountAws[0].account_name;
      setAwsAccount(initialAwsProjectName);
      initialAccountIdAWS = initialAccountAws[0].account_id;
      setAwsAccountId(initialAccountIdAWS);
      setAwsAccounts(resp.data);
      setLoading(true);
      getAWSData(month, initialAccountIdAWS, accessToken);
    }
  };

  const handleProjectChange = (value) => {
    setLoading(true);
    setProject(value);
    getGCPData(month, value, "");
  };
  let projectOptions = projects.map((project) => {
    return (
      <Option name={project.project_name} value={project.project_name}>
        {project.project_name}
      </Option>
    );
  });

  const handleAzureProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    let filteredDataAzure = azureAccounts.filter((account) => {
      if (value == account.account_id) {
        setAzureAccount(account.account_name);
      }
    });
    setLoading(true);
    setAzureAccountId(value);
    getAzureData(month, value, accessToken);
  };

  let accountsOptions = azureAccounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  const handleAwsProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    let filteredDataAws = awsAccounts.filter((accountAws) => {
      if (value == accountAws.account_id) {
        setAwsAccount(accountAws.account_name);
      }
    });
    setLoading(true);
    setAwsAccountId(value);
    getAWSData(month, value, accessToken);
  };

  let accountsOptionsAws = awsAccounts.map((accounts) => {
    return (
      <Option name={accounts.account_name} value={accounts.account_id}>
        {accounts.account_name}
      </Option>
    );
  });

  const options = [
    {
      value: "Azure",
      label: "Azure",
    },
    {
      value: "AWS",
      label: "AWS",
    },
    {
      value: "GCP",
      label: "GCP",
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
          <Select
            placeholder="Select Cloud"
            style={{
              width: "95%",
            }}
            onChange={handleChange}
            options={options}
            defaultValue="Azure"
            clearIcon
            bordered
          />
        </Col>
        {cloudType === "GCP" ? (
          <div>
            <Row>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <DatePicker
                  className="selectDateGCP"
                  allowClear={false}
                  disabledDate={disabledDate}
                  onChange={handleMonthChange}
                  defaultValue={moment()}
                  format={monthFormat}
                  picker="month"
                />
              </Col>
              <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                <Select
                  value={project}
                  mode="projects"
                  className="selectProjectGCP"
                  placeholder="Select Projects"
                  onChange={handleProjectChange}
                  showArrow
                  clearIcon
                >
                  {projectOptions}
                </Select>
              </Col>
            </Row>
          </div>
        ) : null}
        {cloudType === "Azure" ? (
          <div>
            <Row>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <DatePicker
                  className="selectDateGCP"
                  allowClear={false}
                  disabledDate={disabledDate}
                  onChange={handleAzureMonthChange}
                  defaultValue={moment()}
                  format={monthFormat}
                  picker="month"
                />
              </Col>
              <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                <Select
                  value={azureAccount}
                  mode="azureAccount"
                  className="selectProjectGCP"
                  placeholder="Select Account"
                  onChange={handleAzureProjectChange}
                  showArrow
                  clearIcon
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {accountsOptions}
                </Select>
              </Col>
            </Row>
          </div>
        ) : null}

        {cloudType === "AWS" ? (
          <div>
            <Row>
              <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                <DatePicker
                  className="selectDateGCP"
                  allowClear={false}
                  disabledDate={disabledDate}
                  onChange={handleAwsMonthChange}
                  defaultValue={moment()}
                  format={monthFormat}
                  picker="month"
                />
              </Col>
              <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                <Select
                  value={awsAccount}
                  mode="awsAccount"
                  className="selectProjectGCP"
                  placeholder="Select Account"
                  onChange={handleAwsProjectChange}
                  showArrow
                  clearIcon
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {accountsOptionsAws}
                </Select>
              </Col>
            </Row>
          </div>
        ) : null}
      </Row>
    </div>
  );
}
