import { combineReducers } from "redux";
import chartReducer from "./chartReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
const rootReducer = combineReducers({
  errors: errorReducer,
  users: userReducer,
  chartData: chartReducer,
});

export default rootReducer;
