import React, { useState, useRef } from "react";
import { Row, Col, Select } from "antd";
import gcp from "../../assests/img/gcp.png";
import { GetBillingsByAccount } from "./charts/GCP/Column-Group-Chart/GetBillingsByProjectMonthly";
import { GetMonthWiseDetails } from "./charts/GCP/Pie-Chart/GetMonthWiseDetails";
import { GetProjectWiseDetails } from "./charts/GCP/Pie-Chart/GetProjectWiseDetails";
import { GetTotalMonthlyBar } from "./charts/GCP/Basic-Column/GetTotalMonthlyBar";
import { GetMonthWiseDetailsBar } from "./charts/GCP/Basic-Column/GetMonthWiseDetailsBar";
import { GetWeeklyBillingBar } from "./charts/GCP/Column-Group-Chart/GetWeeklyBillingBar";
import { GetAllRegionWiseBilling } from "./charts/GCP/Pie-Chart/GetAllRegionWiseBilling";
import { GetAllServicewiseBilling } from "./charts/GCP/Pie-Chart/GetAllServicewiseBilling";
import { GetCostCreditsBar } from "./charts/GCP/Column-Group-Chart/GetCostCreditsBar";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { DownloadOutlined } from "@ant-design/icons/lib/icons";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";

const { Option } = Select;

const GCPDashboard = () => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [currency, setCurrency] = useState("INR");
  const currencies = ["USD", "INR"];

  const handleCurrencyChange = async (value) => {
    setCurrency(value);
  };

  let currencyOptions = currencies.map((cur) => {
    return (
      <Option name={cur} value={cur}>
        {cur}
      </Option>
    );
  });

  return (
    <div id="divToPrint" ref={myRef}>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={18} sm={18} md={18} lg={18} xl={18}>
            <div className="d-flex flex-row">
              <div className="pages-header">Google Cloud Platform</div>
              <img
                style={{ margin: "6.5px 0 0 5px" }}
                src={gcp}
                width={25}
                height={25}
              />
            </div>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            {currency && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  style={{
                    width: "62%",
                    float: "right",
                  }}
                  defaultValue={currencies[1]}
                  showSearch
                  placeholder="Select Currency"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={handleCurrencyChange}
                >
                  {currencyOptions}
                </Select>
              </Col>
            )}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            {" "}
            <Tooltip title="Export to PDF">
              <div className="download-btn float-end aws-sb-btn3">
                <Button size="large" type="text" onClick={printDocument}>
                  <DownloadOutlined />
                </Button>
              </div>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetProjectWiseDetails Currency={currency} />
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetAllServicewiseBilling Currency={currency} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetMonthWiseDetails Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetTotalMonthlyBar Currency={currency} />
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetBillingsByAccount Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetWeeklyBillingBar Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetMonthWiseDetailsBar Currency={currency} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetAllRegionWiseBilling Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetCostCreditsBar Currency={currency} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GCPDashboard;
