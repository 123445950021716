import {
  ADD_LIMIT,
  DELETE_ACCOUNT_LIMIT,
  GET_ACCOUNT_LIMIT_LIST,
  GET_AWS_ACCOUNTS_LIST,
  GET_AZURE_ACCOUNTS_LIST,
  GET_GCP_ACCOUNTS_LIST,
} from "./../actions/type";

const initialState = {
  user: {},
  users: [],
  awsAccounts: [],
  azureAccounts: [],
  gcpAccounts: [],
  accLimitList: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AWS_ACCOUNTS_LIST:
      return {
        ...state,
        awsAccounts: action.payload,
      };
    case GET_AZURE_ACCOUNTS_LIST:
      return {
        ...state,
        azureAccounts: action.payload,
      };

    case GET_GCP_ACCOUNTS_LIST:
      return {
        ...state,
        gcpAccounts: action.payload,
      };
    case GET_ACCOUNT_LIMIT_LIST:
      return {
        ...state,
        accLimitList: action.payload,
      };
    case ADD_LIMIT:
      return {
        ...state,
        accLimitList: [
          action.payload,
          ...state.accLimitList.filter(
            (limit) => limit.id !== action.payload.id
          ),
        ],
      };
    case DELETE_ACCOUNT_LIMIT:
      var updatedLimits = [];
      updatedLimits = state.accLimitList.filter((limit) => {
        if (limit.id !== action.payload) return limit;
      });
      return {
        ...state,
        accLimitList: updatedLimits,
      };
    default:
      return state;
  }
}
