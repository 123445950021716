import React, { Component } from "react";
import {
  AmazonOutlined,
  WindowsFilled,
  GoogleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Button, Row, Col, Skeleton } from "antd";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import AccountsList from "./AccountsList";
import { routes } from "../../../util/constants";
import { Auth } from "aws-amplify";

export default class CountBillCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      gcpCardData: [],
      azureCardData: [],
      awsCardData: [],
    };
  }
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    const defaultMonth = moment(new Date()).month() + 1;
    const defaultYear = moment(new Date()).year();
    let checkStartOfMonth = moment().format("D");
    var initalMonth;

    if (checkStartOfMonth === 1) {
      initalMonth =
        moment(new Date()).month() + "-" + moment(new Date()).year();
    } else {
      initalMonth = defaultYear + "-" + defaultMonth;
    }
    this.getDashboardData(initalMonth, accessToken);
  }
  getDashboardData = async (month, accessToken) => {
    this.getCardData(accessToken);
  };
  getCardData = async (accessToken) => {
    let awsResp = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/dashboard/forecast`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    let obj = awsResp.data.map((ele) => {
      if (ele.cloud === "Aws") {
        console.log("inside aws cloud", ele);
        let awsData = {
          type: "AWS",
          PreviousValue: ele.last_month,
          CurrentValue: ele.current_month,
          ForecastValue: ele.forecast,
        };
        let dashboardData = this.state.cardData.filter((value, index) => {
          return value.type !== "AWS";
        });
        dashboardData.push(awsData);
        this.setState({
          cardData: dashboardData,
          awsCardData: awsData,
        });
      }
      if (ele.cloud === "Azure") {
        console.log("inside azure cloud");
        let azureData = {
          type: "AZURE",
          PreviousValue: ele.last_month,
          CurrentValue: ele.current_month,
          ForecastValue: ele.forecast,
        };

        let dashboardData = this.state.cardData.filter((value, index) => {
          return value.type !== "AZURE";
        });
        dashboardData.push(azureData);
        this.setState({
          cardData: dashboardData,
          azureCardData: azureData,
        });
      }
      if (ele.cloud === "Gcp") {
        let gcpData = {
          type: "GCP",
          PreviousValue: ele.last_month,
          CurrentValue: ele.current_month,
          ForecastValue: ele.forecast,
        };

        let dashboardData = this.state.cardData.filter((value, index) => {
          return value.type !== "GCP";
        });

        dashboardData.push(gcpData);
        this.setState({
          cardData: dashboardData,
          gcpCardData: gcpData,
        });
      }
    });
  };

  render() {
    let data = this.state.cardData;
    return (
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="count-cards mt-3">
            <Row gutter={[24, 8]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body3">
                    <div className="details">
                      <AmazonOutlined />
                      <span className="count ms-3">
                        <span className="name-cloud">Amazon Web Services</span>
                        <h6>
                          {this.state.awsCardData.length === 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Previous</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.awsCardData.PreviousValue}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Current</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.awsCardData.CurrentValue}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Forecast</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.awsCardData.ForecastValue}
                                </Col>
                              </Row>
                            </>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="arrow-button">
                      <Link to={routes.AWS_BILLINGS}>
                        <Button size="large" type="text">
                          <ArrowRightOutlined style={{ color: "white" }} />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body2">
                    <div className="details">
                      <GoogleOutlined />
                      <span className="count ms-3">
                        <span className="name-cloud">
                          Google Platform Services
                        </span>
                        <h6>
                          {this.state.gcpCardData.length === 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Previous</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.gcpCardData.PreviousValue}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Current</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.gcpCardData.CurrentValue}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h5 className="name-cloud-types">Forecast</h5>
                                </Col>
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.gcpCardData.ForecastValue}
                                </Col>
                              </Row>
                            </>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="buttons">
                      <Link to={routes.GCP_BILLINGS}>
                        <Button size="large" type="text">
                          <ArrowRightOutlined style={{ color: "white" }} />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body1">
                    <div className="details">
                      <WindowsFilled />
                      <span className="count ms-3">
                        <span className="name-cloud">Microsoft Azure</span>

                        <h6>
                          {this.state.azureCardData.length === 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <>
                              <Row>
                                <Col>
                                  <h5 className="name-cloud-types">Previous</h5>
                                </Col>
                                <Col className="text-white">
                                  &nbsp; &nbsp;
                                  <i className="fa fa-dollar"></i>
                                  {this.state.azureCardData.PreviousValue.toFixed(
                                    2
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h5 className="name-cloud-types">Current</h5>
                                </Col>
                                &nbsp;&nbsp; &nbsp;
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.azureCardData.CurrentValue}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <h5 className="name-cloud-types">Forecast</h5>
                                </Col>
                                &nbsp; &nbsp;
                                <Col className="text-white">
                                  <i className="fa fa-dollar"></i>
                                  {this.state.azureCardData.ForecastValue}
                                </Col>
                              </Row>
                            </>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="buttons">
                      <Link to={routes.AZURE_BILLINGS}>
                        <Button size="large" type="text">
                          <ArrowRightOutlined style={{ color: "white" }} />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row gutter={[24, 8]} className="mt-5">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <AccountsList />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
