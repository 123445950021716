import axios from "axios";
import {
  ADD_LIMIT,
  DELETE_ACCOUNT_LIMIT,
  GET_ACCOUNT_LIMIT_LIST,
  GET_AWS_ACCOUNTS_LIST,
  GET_AZURE_ACCOUNTS_LIST,
  GET_ERRORS,
  GET_GCP_ACCOUNTS_LIST,
} from "./type";
import { message } from "antd";
import { Auth } from "aws-amplify";

//Get All AWS Accounts
export const getAwsAccounts = (month, accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_AWS_ACCOUNTS_LIST,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get All GCP Accounts
export const getGcpAccounts = (month, accessToken) => async (dispatch) => {
  try {
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectlist`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    dispatch({
      type: GET_GCP_ACCOUNTS_LIST,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Get All Azure Accouts
export const getAzureAccounts = (month) => async (dispatch) => {
  let accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  try {
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_AZURE_ACCOUNTS_LIST,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Add Account Limit
export const addAccountLimit = (obj) => async (dispatch) => {
  let accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  try {
    let response = await axios.post(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/common/cloudalert`,
      obj,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: ADD_LIMIT,
      payload: response.data.body[0],
    });
    message.success(`The Limit Is Set Successfully.`);
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};

//Get Accounts Limit
export const getAccountsLimit = (month) => async (dispatch) => {
  let accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  try {
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/common/cloudalert`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: GET_ACCOUNT_LIMIT_LIST,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};

//Delete Account Limit
export const deleteAccountLimit = (id) => async (dispatch) => {
  let accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();
  try {
    let res = await axios.delete(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/common/cloudalert`,
      {
        data: {
          id,
        },
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    dispatch({
      type: DELETE_ACCOUNT_LIMIT,
      payload: id,
    });
    message.success(`Limit Deleted Successfully`);
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error,
    });
  }
};
