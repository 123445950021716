import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Tooltip,
  Row,
  Col,
  Empty,
  Select,
  Spin,
  DatePicker,
  Table,
  Input,
  Space,
} from "antd";
import { G2 } from "@ant-design/plots";
import { CSVLink } from "react-csv";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../util/constants";
import axios from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";
import Highlighter from "react-highlight-words";

const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
var initialProjectName = "";
const { Option } = Select;
var initialCurrency = "INR";
const G = G2.getEngine("canvas");

export const GetAllServicewiseBillingTable = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");
  const [month, setMonth] = useState(initalMonth);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setCurrency(Currency);
      setprojectName("");
      setLoading(true);
      setData([]);
      let initialProject = [];
      fetch(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectlist`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((json) => {
          initialProject = json;
          initialProjectName = initialProject[0].project_name;
          setprojectName(initialProjectName);
          setProjects(json);
          setCurrency(Currency);
          setLoading(true);

          asyncFetch(month, initialProjectName, Currency, accessToken);
        })
        .catch((error) => {
          console.log("fetch data failed", error);
        });
    })();
  }, [Currency]);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const asyncFetch = async (month, projectname, Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/servicescost?month=${month}&currency=${Currency}&projectName=${projectname}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      if (resp.data) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, project, currency, accessToken);
  };
  const handleProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setprojectName(value);
    asyncFetch(month, value, currency, accessToken);
  };

  let projectOptions = projects.map((project) => {
    return (
      <Option name={project.project_name} value={project.project_name}>
        {project.project_name}
      </Option>
    );
  });
  const dataSource = data;
  const columns = [
    {
      title: "Project",
      dataIndex: "projectname",
      key: "projectname",
      sortDirections: ["ascend"],
      sorter: (a, b) => a.projectname - b.projectname,
      ...getColumnSearchProps("projectname"),
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Service",
      dataIndex: "serviceid",
      key: "serviceid",
    },
    {
      title: currency === "USD" ? "Cost($)" : "Cost(₹)",
      dataIndex: "cost",
      key: "cost",
    },
  ];

  const headers = [
    { label: "Name", key: "project_name" },
    { label: "Cost", key: "total_exact" },
    { label: "Month", key: "month" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary mb-3">Service Billings</h3>
              <h5 className="text-secondary mt-2 ms-3">Month & Project wise</h5>
            </div>
            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    allowClear={false}
                    disabledDate={disabledDate}
                    onChange={handleMonthChange}
                    defaultValue={moment(initalMonth, monthFormat)}
                    format={monthFormat}
                    picker="month"
                  />
                </Col>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Select
                    defaultValue={project}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select Project"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleProjectChange}
                  >
                    {projectOptions}
                  </Select>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.GCP_TYPE + "Project Billing_Monthly"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>

                  <Tooltip title="Export to PDF">
                    <div className="download-btn float-end aws-sb-btn3">
                      <Button size="large" type="text" onClick={printDocument}>
                        <FilePdfOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )}

            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return (
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    scroll={{ y: 300 }}
                  />
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
