import React, { Component } from "react";
import {
  Button,
  Space,
  Table,
  Input,
  Row,
  Col,
  Popconfirm,
  Tooltip,
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons/lib/icons";
import Highlighter from "react-highlight-words";
import { Spin } from "antd";
import { connect } from "react-redux";
import {
  getAccountsLimit,
  deleteAccountLimit,
} from "../../../actions/userAction";
import EditCloudLimitAlert from "./EditCloudLimitAlert";
import moment from "moment";
import { Auth } from "aws-amplify";
import AddCloudLimit from "./AddCloudLimit";

class CloudLimitDetailsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      filteredInfo: {},
      sortedInfo: {},
    };
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({ filteredInfo: filters, sortedInfo: sorter });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.getAccountsLimit(accessToken);
  }

  deleteLimit = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.deleteAccountLimit(id, accessToken);
  };
  render() {
    const columns = [
      {
        title: "Cloud",
        dataIndex: "cloud_name",
        key: "cloud_name",
        filters: [
          { text: "AWS", value: "AWS" },
          { text: "Azure", value: "Azure" },
          { text: "GCP", value: "GCP" },
        ],
        filteredValue: this.state.filteredInfo.cloud_name || null,
        onFilter: (value, record) => record.cloud_name.includes(value),
      },
      {
        title: "Account",
        dataIndex: "service_account_name",
        key: "service_account_name",
        ...this.getColumnSearchProps("service_account_name"),
      },
      {
        title: "Email",
        dataIndex: "notification_emails",
        key: "notification_emails",
        ...this.getColumnSearchProps("notification_emails"),
        render: (record) => (
          <>
            {record?.map((email) => {
              return <div>{email}</div>;
            })}
          </>
        ),
      },
      {
        title: "Daily Limit",
        dataIndex: "daily_limit",
        key: "daily_limit",
      },
      {
        title: "Weekly Limit",
        dataIndex: "weekly_limit",
        key: "weekly_limit",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (record) => <>{moment(record).format("MMM Do YYYY, h:mm a")}</>,
      },
      {
        title: "Action",
        key: "action",
        render: (record) => (
          <Row gutter={[0, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditCloudLimitAlert alertDetails={record} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Popconfirm
                title="Are you sure to delete this Limit?"
                onConfirm={() => this.deleteLimit(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" size="small">
                  <Tooltip title="Delete Alert Limit">
                    <DeleteOutlined />
                  </Tooltip>
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ),
      },
    ];
    return (
      <div className="my-4 cloud-charts">
        <div className="card">
          <div className="card-body p-5">
            <div className="">
              <Row>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <h2 className="m-b-20">Account Wise Alert</h2>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div className="float-end">
                    <AddCloudLimit />
                  </div>
                </Col>
              </Row>
            </div>
            {this.props.limits.length ? (
              <Table
                columns={columns}
                dataSource={this.props.limits}
                onChange={this.handleChange}
              />
            ) : (
              <div className="spinLoader">
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  limits: state.users.accLimitList,
});
export default connect(mapStateToProps, {
  getAccountsLimit,
  deleteAccountLimit,
})(CloudLimitDetailsList);
