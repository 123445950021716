import React, { useState, useEffect, useRef } from "react";
import {
  Empty,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Tooltip,
  Spin,
} from "antd";
import { Pie, G2 } from "@ant-design/plots";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";

const G = G2.getEngine("canvas");
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
let initialAccountId;
const { Option } = Select;
var initialProjectName = "";
var initialCurrency = "INR";

export const GetTotalMonthlyBillingsAzure = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [account, setAccountId] = useState();
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let initialAccount = [];

      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0].account_name;
      setprojectName(initialProjectName);
      initialAccountId = initialAccount[0].account_id;
      setAccountId(initialAccountId);
      setAccounts(resp.data);
      setCurrency(Currency);
      setLoading(true);
      setData([]);

      asyncFetch(month, initialAccountId, Currency);
    })();
  }, [Currency]);

  const asyncFetch = async (month, accountId, Currency) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    try {
      let resp = await axios.post(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/servicescost?monthYear=${month}&currency=${Currency}&accountId=${accountId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data) setLoading(false);
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };

  const config = {
    appendPadding: 10,
    data,
    angleField: "cost",
    colorField: "service_name",
    radius: 0.8,

    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 10,
            text: `${data.cost}`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 400,
          },
        });
        return group;
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
      {
        type: "element-selected",
      },
    ],
  };

  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, account, Currency, accessToken);
  };

  const handleAccountChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setAccountId(value);
    asyncFetch(month, value, currency, accessToken);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  let accountsOptions = accounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });
  const headers = [
    {
      label: "Account Id",
      key: "account_id",
    },
    { label: "Service Name", key: "service_name" },
    { label: "Cost", key: "cost" },
    { label: "Date", key: "date" },
    { label: "Currency", key: "currency" },
    { label: "MonthYear", key: "month_year" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Services Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">
                Account & Month Wise
              </h5>
            </div>

            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    onChange={handleMonthChange}
                    defaultValue={moment()}
                    format={monthFormat}
                    picker="month"
                  />
                </Col>
                <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={project}
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleAccountChange}
                  >
                    {accountsOptions}
                  </Select>
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.AZURE_TYPE +
                      "Services Billings_Month&AccWise"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                  <Tooltip title="Export to PDF">
                    <div className="download-btn float-end aws-sb-btn3">
                      <Button size="large" type="text" onClick={printDocument}>
                        <FilePdfOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return <Pie {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
