import React, { Component } from "react";
import { Layout, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import { HomeOutlined } from "@ant-design/icons/lib/icons";

const { Header } = Layout;

export default class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
  }

  capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  };

  getFullName = (email) => {
    try {
      email = email.split("_");
      email = email[1].split("@");
      email = email[0].split(".");
      return `${this.capitalize(email[0])} ${this.capitalize(email[1])}`;
    } catch (error) {
      return email;
    }
  };
  render() {
    const items = [
      {
        label: (
          <Link to="/" className="ms-4 fs-4">
            <HomeOutlined />
            <span className="ms-4 menu-item-name">Home</span>
          </Link>
        ),
        key: "0",
      },

      {
        label: (
          <span className="ms-4 fs-4" onClick={this.props.signOut}>
            <LogoutOutlined />
            <span className="ms-4 menu-item-name">Sign Out</span>
          </span>
        ),
        key: "1",
      },
    ];
    return (
      <Header
        className="header-admin site-layout-background"
        style={{ padding: 0 }}
      >
        {React.createElement(
          this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: this.props.toggle,
          }
        )}
        <SiderDrawerPhone user={this.props.user} />
        {/**<span className="sign-out-link ">
          <a className="text-dark" onClick={this.props.signOut}>
            Sign Out
          </a>
        </span> */}

        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <div
            className="avatar-user-details"
            onClick={(e) => e.preventDefault()}
          >
            <div className="avatar-box">
              <span className="avatar-name">
                <strong>{this.getFullName(this.props.user.username)}</strong>
              </span>
              <div className="avatar-img">
                <Avatar
                  style={{ backgroundColor: "#3b404f" }}
                  size={40}
                  icon={<UserOutlined />}
                />
              </div>
            </div>
          </div>
        </Dropdown>
      </Header>
    );
  }
}
