import "./App.css";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./ui-components/layouts/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import "@ant-design/flowchart/dist/index.css";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import { routes, brand } from "./util/constants";
function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const [isLogged, setIsLogged] = useState(false);
  const updateCurrentUser = async (newUser) => {
    if (newUser) {
      await setCurrentUser(newUser);
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsLogged(true);
      setCurrentUser(user);
      setIsLoaded(false);
    } catch (err) {
      console.log("err", err);
      setIsLogged(false);
      console.log("LoggedIn line Catch", isLogged);
      setCurrentUser(null);
      navigate(routes.LOGIN);
      setIsLoaded(true);
    }
  };

  const onHubAuth = (data) => {
    const { payload } = data;
    if (payload.event !== "signIn") {
      updateCurrentUser();
    }
  };

  useEffect(() => {
    document.title = brand.NAME;
    updateCurrentUser();
    Hub.listen("auth", onHubAuth);
  }, []);

  return (
    <Provider store={store}>
      <AmplifyProvider>
        <Routes location={location}>
          <Route
            exact
            path={routes.LOGIN}
            element={
              isLogged ? <Navigate to={routes.ADMIN_DASHBOARD} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.ADMIN_DASHBOARD}
            element={
              isLogged ? (
                <AdminDashboard location={location} />
              ) : (
                <Navigate to={routes.LOGIN} />
              )
            }
          />
          <Route
            exact
            path={routes.AWS_BILLINGS}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.AZURE_BILLINGS}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.GCP_BILLINGS}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.AWS_DATA_TABLE}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.AZURE_DATA_TABLE}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.GCP_DATA_TABLE}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.TAG_MANAGEMENT}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.CLOUD_LIMIT}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.USER_LIST}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
          <Route
            exact
            path={routes.BUDGET_ALERT}
            element={
              isLogged ? <AdminDashboard location={location} /> : <Home />
            }
          />
        </Routes>
      </AmplifyProvider>
    </Provider>
  );
}

export default App;
