import React, { useState, useEffect } from "react";
import { Empty } from "antd";
import { Pie, measureTextWidth } from "@ant-design/plots";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth = defaultYear + "-" + defaultMonth;

export const GCPDonut = () => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const [data, setData] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      asyncFetch(month, accessToken);
    })();
  }, []);

  const asyncFetch = async (month, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/monthscost?month=${month}&currency=USD`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const config = {
    width: 275,
    height: 275,
    color: [
      "rgb(115, 147, 179)",
      "rgb(71, 145, 219)",
      "rgb(17 82 147)",
      "#1976d2",
      "rgb(15, 82, 186)",
      "rgb(137, 207, 240)",
      "rgb(0, 71, 171)",
      "rgb(70, 130, 180)",
      "rgb(100, 149, 237)",
      "rgb(96, 130, 182)",
      "rgb(167, 199, 231)",
    ],
    appendPadding: 10,
    data,
    angleField: "cost",
    colorField: "project_name",
    radius: 1,
    innerRadius: 0.64,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },

    label: {
      type: "spider",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: -4,
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          width: "300px",
          fontSize: 16,
          fontWeight: "500",
          opacity: "0.9",
        },
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.project_name : "Total";

          return renderStatistic(d, text, {
            fontSize: 18,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "200",
          opacity: "0.7",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `$ ${datum.cost}`
            : `$ ` +
              data.reduce((r, d) => {
                return {
                  cost: r.cost + d.cost,
                };
              }).cost;

          return renderStatistic(width, text, {
            fontSize: 18,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <h3 className="text-secondary  mb-3">Project Billings</h3>
        <h5 className="text-secondary  mt-2 ms-3">Current Month</h5>
      </div>

      {(() => {
        if (data.length === 0) {
          return (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
                margin: "0 0 0 34%",
              }}
            />
          );
        } else {
          return <Pie {...config} />;
        }
      })()}
    </div>
  );
};
