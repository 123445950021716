import React, { useState } from "react";
import { Row, Col } from "antd";
import { TagDetailsList } from "./TagDetailsList";
import moment from "moment";

const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth == 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const monthFormat = "YYYY-MM";

export const TagManagement = () => {
  const [cloudType, setCloudType] = useState("Azure");
  const [selected, setSelected] = useState(false);
  const [month, setMonth] = useState(initalMonth);
  const [reload, setReload] = useState(false);
  const rowSelected = (selected) => {
    setSelected(selected);
  };

  const reloadPage = () => {
    setReload(true);
  };

  return (
    <div className="container dashboard statistics-card">
      <Row className="title-row">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="d-flex flex-row">
            <div className="pages-header">Resource Cost & Tags</div>
          </div>
          <div></div>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <TagDetailsList
            cloudType={cloudType}
            month={month}
            rowSelected={rowSelected}
          />
        </Col>
      </Row>
    </div>
  );
};
