import React, { useState, useEffect } from "react";
import { Modal, Col, Button, Tooltip, Form, Input, Space, message } from "antd";
import moment from "moment";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { Auth } from "aws-amplify";

const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");

if (checkStartOfMonth == 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

export default function AddTagFormOne(props) {
  const [visible, setVisible] = useState(false);
  const [month, setMonth] = useState(initalMonth);
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [submitDisable, setSubmitDisable] = useState(false);
  const [buttonVisible, setbuttonVisible] = useState(true);
  const [selectedServices, setSelectedServices] = useState([]);

  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    setSelectedServices(props.selectedServices);
  }, [props]);

  const handleModalCancel = () => {
    setVisible(false);
    setItems([]);
    setValue("");
    setSelectedServices([]);
    setSubmitDisable(false);
  };

  const handleOk = () => {};

  const onFinish = async (values) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    if (props.cloudType === "AWS") {
      setSubmitDisable(true);
      let temp = values["tags"];
      let awsTagObj = {};
      awsTagObj.tagsArray = temp;
      awsTagObj.ResourceARNList = [props.selectedServices[0].resource_id];
      console.log("-------final aws obj tag-------", awsTagObj);
      axios
        .post(
          `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/tags`,
          awsTagObj,
          { headers: { Authorization: "Bearer " + accessToken } }
        )
        .then((response) => {
          message.success(response.data);
          props.tagAdded();
          setVisible(false);
          setSelectedServices([]);
          setSubmitDisable(false);
        })
        .catch((err) => {
          message.error("Cannot be created, something went wrong");
        });
    } else if (props.cloudType === "Azure") {
      setSubmitDisable(true);
      let temp = values["tags"];

      let azureTagObj = {};
      // let keys = Object.keys(props.selectedServices[0].tags);

      // keys.forEach((element) => {
      //   let a = {};
      //   a["key"] = element;
      //   a["value"] = props.selectedServices[0].tags[element];
      //   temp.push(a);
      // });

      azureTagObj.queryStringParameters = {
        accountId: props.selectedServices[0].AccountId,
        resourceId: props.selectedServices[0].ResourceId,
        locationName: props.selectedServices[0].ResourceLocation,
        Tags: temp,
      };
      console.log("-------final azure obj tag-------", azureTagObj);
      axios
        .put(
          `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/resourcetags`,
          azureTagObj,
          { headers: { Authorization: "Bearer " + accessToken } }
        )
        .then((response) => {
          console.log("Tag Created with ID : ", response);
          if (response.status == 200) {
            message.success("Tag created successfully!");
            props.tagAdded(month);
            setVisible(false);
            temp = [];
            setSelectedServices([]);
          } else {
            message.error("Cannot be created, something went wrong");
            temp = [];
          }
          setSelectedServices([]);
          setSubmitDisable(false);
        });
    } else if (props.cloudType === "GCP") {
      setSubmitDisable(true);
      let temp = values["tags"];
      let gcpTagObj = {};
      gcpTagObj.parentvalue = "organizations/37102066520";
      gcpTagObj.resourceName = props.selectedServices[0].global_name;
      gcpTagObj.tags = temp;
      gcpTagObj.region = "us-east1-b";
      console.log("-------final gcp obj tag-------", gcpTagObj);
      axios
        .post(
          `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/tags`,
          gcpTagObj,
          { headers: { Authorization: "Bearer " + accessToken } }
        )
        .then((response) => {
          console.log("Tag Created with ID : ", response.data);
          if (response.data.statusCode == 200) {
            message.success("Tag created successfully!");
            props.tagAdded();
            setVisible(false);
            setSelectedServices([]);
          } else {
            message.error("Cannot be created, something went wrong");
          }
          setSelectedServices([]);
          setSubmitDisable(false);
        });
    }
  };
  return (
    <span>
      <div>
        <Tooltip title={buttonVisible ? "Please select a service" : ""}>
          <Button
            disabled={!props.disabled}
            onClick={showModal}
            className="float-end"
          >
            Add Tags
          </Button>
        </Tooltip>

        <Modal
          width={500}
          open={visible}
          onOk={handleOk}
          onCancel={handleModalCancel}
          title="Add Tag"
          style={{ top: 75 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
        >
          <Form
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{
              maxWidth: 550,
            }}
            autoComplete="off"
            initialValues={{ tags: [""] }}
          >
            <Form.List name="tags">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Col>
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Tag Key",
                              pattern: new RegExp(
                                /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                              ),
                            },
                          ]}
                        >
                          <Input placeholder="Enter Key" />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Tag value",
                              pattern: new RegExp(
                                /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                              ),
                            },
                          ]}
                        >
                          <Input placeholder="Enter Value" />
                        </Form.Item>
                      </Col>
                      <PlusCircleOutlined onClick={() => add(name)} />
                      {key == 0 ? null : (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      )}
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button
                disabled={submitDisable}
                type="primary"
                htmlType="submit"
                className="ok-modal"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </span>
  );
}
