import React, { useState, useEffect, useRef } from "react";
import { Column } from "@ant-design/plots";
import { Select, Row, Col, Empty, Tooltip, Button, Spin } from "antd";
import { CSVLink } from "react-csv";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import axios from "axios";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

const { Option } = Select;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");

if (checkStartOfMonth === 1) {
  initalMonth = moment(new Date()).month() + "-" + moment(new Date()).year();
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
let initialAccountId;

const monthFormat = "YYYY-MM";
var initialProjectName = "";

export const GetBillingsByAccountAzure = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };
  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState();
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const [month, setMonth] = useState(initalMonth);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0]?.account_name;
      setprojectName(initialProjectName);
      initialAccountId = initialAccount[0]?.account_id;
      setAccountId(initialAccountId);
      setAccounts(resp.data);
      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(Currency, initialAccountId, accessToken);
    })();
  }, [Currency]);
  const asyncFetch = async (crr, accountId, accessToken) => {
    try {
      setLoading(true);
      let response = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/allaccountscost?currency=${crr}&accountId=${accountId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (response) {
        setLoading(false);
      }
      const filteredData = response.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const handleChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    asyncFetch(currency, value, accessToken);
    setAccountId(value);
  };

  const config = {
    data,
    xField: "month_year",
    yField: "cost",
    seriesField: "service_name",
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  };
  let accountsOptions = accounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  const headers = [
    { label: "Service Name", key: "service_name" },
    { label: "Account Id", key: "account_id" },
    { label: "Cost", key: "cost" },
    { label: "Month", key: "month_year" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Services Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">
                Account Wise for Multiple Months
              </h5>
            </div>

            {project && (
              <Row gutter={[8, 8]} className="my-2">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    defaultValue={project}
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleChange}
                  >
                    {accountsOptions}
                  </Select>
                </Col>

                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.AWS_TYPE + "Service Billings_AccWise"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn float-end aws-sb-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                  <Tooltip title="Export to PDF">
                    <div className="download-btn float-end aws-sb-btn2">
                      <Button size="large" type="text" onClick={printDocument}>
                        <FilePdfOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return <Column {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
