import React from "react";
import { Checkbox, Table } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons/lib/icons";
import { Row, Col } from "antd";

export default function UserListDetails() {
  const dataSource = [
    {
      key: "1",
      name: "Yash Tech",
      email: "tech@yash.com",
      awsAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      gcplert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      azureAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      emailAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      whatsApp: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      ivrs: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
    },
    {
      key: "2",
      name: "PTAG",
      email: "ptag@yash.com",
      awsAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      gcplert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      azureAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      emailAlert: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      whatsApp: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
      ivrs: (
        <>
          <Checkbox></Checkbox>
        </>
      ),
    },
  ];

  const userColumns = [
    {
      title: "#",
      dataIndex: "",
      key: "",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "AWS Alert",
      dataIndex: "awsAlert",
      key: "awsAlert",
      align: "center",
    },
    {
      title: "GCP Alert",
      dataIndex: "gcplert",
      key: "gcpAlert",
      align: "center",
    },
    {
      title: "Azure Alert",
      dataIndex: "azureAlert",
      key: "azureAlert",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "emailAlert",
      key: "emailAlert",
      align: "center",
    },
    {
      title: "WhatsApp",
      dataIndex: "whatsApp",
      key: "whatsApp",
      align: "center",
    },
    {
      title: "Call",
      dataIndex: "ivrs",
      key: "ivrs",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      render: () => (
        <Row gutter={[0, 8]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SaveOutlined />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <DeleteOutlined />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="my-4 cloud-charts">
      <div className="card">
        <div className="card-body p-5">
          <div className="d-flex flex-row my-2"></div>
          <Table columns={userColumns} dataSource={dataSource} />
        </div>
      </div>
    </div>
  );
}
