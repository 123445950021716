import React, { Component } from "react";
import CountBillCards from "./CountBillCards";
import { GraphDashboard } from "./GraphDashboard";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="container-fluid statistics-card ">
        {/**USE THIs On LINE 14 <div className="container dashboard statistics-card"> */}
        <GraphDashboard />
        <span className="div-dash">
          <CountBillCards />
        </span>
      </div>
    );
  }
}
