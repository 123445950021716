import React, { Component } from "react";
import { Modal, Row, Col, Select, Button, Form, Input } from "antd";
import { connect } from "react-redux";
import {
  getAwsAccounts,
  getGcpAccounts,
  getAzureAccounts,
  addAccountLimit,
} from "../../../actions/userAction";
import moment from "moment";
import { Auth } from "aws-amplify";

const { Option } = Select;

const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

class AddCloudLimit extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      month: initalMonth,
      cloudType: "",
      accountName: "",
      accountId: "",
      weeklyLimit: "",
      dailyLimit: "",
      items: [],
      value: "",
      emailError: null,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onAccountChange = (event, key) => {
    this.setState({
      accountName: key.value,
      accountId: key.key,
    });
  };

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  async onSubmit(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    let obj = {
      cloud_name: this.state.cloudType,
      service_account_name: this.state.accountName,
      service_account_id: this.state.accountId,
      daily_limit: this.state.dailyLimit,
      weekly_limit: this.state.weeklyLimit,
      notification_emails: this.state.items,
    };
    this.props.addAccountLimit(obj, accessToken);
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false, items: [] });
    }, 1000);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false, items: [], value: "", weeklyLimit: "" });
  };
  handleTypeChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    this.setState({ cloudType: value });

    if (value === "AWS") {
      return this.props.getAwsAccounts(this.state.month, accessToken);
    } else if (value === "AZURE") {
      return this.props.getAzureAccounts(this.state.month, accessToken);
    } else if (value === "GCP") {
      return this.props.getGcpAccounts(this.state.month, accessToken);
    }
  };
  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
        });
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
      emailError: null,
    });
  };

  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  isValid(email) {
    let emailError = null;

    if (this.isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    if (emailError) {
      this.setState({ emailError });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }
  render() {
    const { visible, loading } = this.state;
    return (
      <span className="add-col-row">
        <div className="add-button">
          <Button onClick={this.showModal}>Set Account Limit</Button>
          <Modal
            width={700}
            open={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Set a Limit"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              ref={this.formRef}
              name="add-SBU"
              className="add-SBU"
              onFinish={this.onSubmit}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="cloudType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Type!",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select Cloud Type"
                      onChange={this.handleTypeChange}
                      options={[
                        {
                          value: "AWS",
                          label: "AWS",
                        },
                        {
                          value: "AZURE",
                          label: "AZURE",
                        },
                        {
                          value: "GCP",
                          label: "GCP",
                        },
                      ]}
                      name="cloudType"
                      value={this.state.cloudType}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="accountName"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Account Name!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      name="accountName"
                      placeholder="Select an Account"
                      onChange={this.onAccountChange}
                      value={this.state.accountName}
                    >
                      {(() => {
                        if (this.state.cloudType === "AWS") {
                          return this.props.awsAccounts.map((acc) => (
                            <Option
                              value={acc.account_name}
                              key={acc.account_id}
                            >
                              {acc.account_name}
                            </Option>
                          ));
                        } else if (this.state.cloudType === "AZURE") {
                          return this.props.azureAccounts.map((account) => (
                            <Option
                              value={account.account_name}
                              key={account.account_id}
                            >
                              {account.account_name}
                            </Option>
                          ));
                        } else if (this.state.cloudType === "GCP") {
                          return this.props.gcpAccounts.map((acc) => (
                            <Option value={acc.project_name}>
                              {acc.project_name}
                            </Option>
                          ));
                        }
                      })()}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="weeklyLimit"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Weekly Limit!",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="$"
                      placeholder="Enter Weekly Max Limit"
                      name="weeklyLimit"
                      value={this.state.weeklyLimit}
                      onChange={this.onChange}
                      type="number"
                      min="0"
                      step="0.5"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="dailyLimit"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Daily Limit!",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="$"
                      placeholder="Enter Daily Max Limit"
                      name="dailyLimit"
                      value={this.state.dailyLimit}
                      onChange={this.onChange}
                      type="number"
                      min="0"
                      step="0.5"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {this.state.items.map((item) => (
                    <div className="tag-item mb-2" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}

                  <Input
                    name="value"
                    className={"input" + (this.state.emailError && "has-error")}
                    value={this.state.value}
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                  />

                  {this.state.emailError && (
                    <p className="error">{this.state.emailError}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>

                    {this.state.items.length !== 0 ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={loading}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled>Save</Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      </span>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  awsAccounts: state.users.awsAccounts,
  azureAccounts: state.users.azureAccounts,
  gcpAccounts: state.users.gcpAccounts,
});
export default connect(mapStateToProps, {
  getAwsAccounts,
  getAzureAccounts,
  getGcpAccounts,
  addAccountLimit,
})(AddCloudLimit);
