import React, { Component } from "react";
import { Menu, Drawer } from "antd";
import {
  LayoutFilled,
  AmazonOutlined,
  WindowsFilled,
  GoogleOutlined,
  MenuUnfoldOutlined,
  CloudFilled,
  TagFilled,
  UserOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { brand, routes } from "../../util/constants";
import logo from "../../assests/img/LOGO.png";

const { SubMenu } = Menu;
let SubMenukey = "";
export default class SiderDrawerPhone extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <span>
        <MenuUnfoldOutlined
          onClick={this.showDrawer}
          className="trigger-phone"
        />
        <Drawer
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          open={this.state.visible}
          key={this.state.placement}
        >
          <div className="side-header-small">
            <div className="brand-name">
              <div className="brand-logo">
                <div>
                  <img className="ms-3" src={logo} alt="Logo" />
                </div>
                <span className="app-name" mode="full">
                  {brand.NAME}
                </span>
              </div>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
          >
            <Menu.Item key="/dashboard" icon={<LayoutFilled />}>
              <NavLink className="sider-links " to="/dashboard">
                Dashboard
              </NavLink>
            </Menu.Item>
            <p className="menu-subhead" mode="full">
              ANALYTICS
            </p>
            <Menu.Item
              key={routes.AWS_BILLINGS}
              icon={<AmazonOutlined />}
              title="Amazon Web Services"
            >
              <NavLink className="sider-links " to={routes.AWS_BILLINGS}>
                Amazon Web Services
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.AZURE_BILLINGS}
              icon={<WindowsFilled />}
              title="Microsoft Azure"
            >
              <NavLink className="sider-links " to={routes.AZURE_BILLINGS}>
                Microsoft Azure
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.GCP_BILLINGS}
              icon={<GoogleOutlined />}
              title="Google Cloud Platform"
            >
              <NavLink className="sider-links " to={routes.GCP_BILLINGS}>
                Google Cloud Platform
              </NavLink>
            </Menu.Item>

            <p className="menu-subhead" mode="full">
              TABLE
            </p>
            <Menu.Item
              key={routes.AWS_DATA_TABLE}
              icon={<AmazonOutlined />}
              title="Amazon Web Services"
            >
              <NavLink className="sider-links " to={routes.AWS_DATA_TABLE}>
                Amazon Web Services
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.AZURE_DATA_TABLE}
              icon={<WindowsFilled />}
              title="Microsoft Azure"
            >
              <NavLink className="sider-links " to={routes.AZURE_DATA_TABLE}>
                Microsoft Azure
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.GCP_DATA_TABLE}
              icon={<GoogleOutlined />}
              title="Google Cloud Platform"
            >
              <NavLink className="sider-links " to={routes.GCP_DATA_TABLE}>
                Google Cloud Platform
              </NavLink>
            </Menu.Item>

            <p className="menu-subhead" mode="full">
              SETTINGS
            </p>
            <Menu.Item
              key={routes.TAG_MANAGEMENT}
              icon={<TagFilled />}
              title="Tag Management"
            >
              <NavLink className="sider-links " to={routes.TAG_MANAGEMENT}>
                Resource Cost & Tags
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.CLOUD_LIMIT}
              icon={<CloudFilled />}
              title="Daily Cloud Limit Alert"
            >
              <NavLink className="sider-links " to={routes.CLOUD_LIMIT}>
                Daily Cloud Limit Alert
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={routes.BUDGET_ALERT}
              icon={<AlertOutlined />}
              title="Budget Alert"
            >
              <NavLink className="sider-links " to={routes.BUDGET_ALERT}>
                Budget Alert{" "}
              </NavLink>
            </Menu.Item>

            {/* <Menu.Item
              key={routes.USER_LIST}
              icon={<UserOutlined />}
              title="User"
            >
              <NavLink className="sider-links " to={routes.USER_LIST}>
                Users
              </NavLink>
            </Menu.Item> */}
          </Menu>
        </Drawer>
      </span>
    );
  }
}
