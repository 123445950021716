import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Tooltip,
  Popconfirm,
  Button,
  Modal,
  message,
  Form,
  Card,
  Collapse,
} from "antd";
import { Badge } from "antd";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons/lib/icons";
import AddAwsBudgetAlert from "./AddAwsBudgetAlert";
const { Panel } = Collapse;
const { Option } = Select;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
let initialAccountIdAWS;
var initialRegionName = "";

export default function AwsBudgetAlert() {
  const formRef = React.createRef();
  const [awsAccount, setAwsAccount] = useState("");
  const [awsAccountId, setAwsAccountId] = useState("");
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [budgetAlert, setBudgetAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [addAlertAction, setAddAlertAction] = useState("");
  const [awsAlertActions, setAwsAlertActions] = useState([]);
  const [rowExpanded, setRowExpanded] = useState([false]);
  const [rowLoading, setRowLoading] = useState(false);
  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [actionChange, setActionChange] = useState("STOP_RDS_INSTANCES");
  const [instances, setInstances] = useState([]);
  const [instanceChange, setInstanceChange] = useState("");
  const [threshold, setThreshold] = useState("");
  const [instanceLoading, setInstanceLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  var initialAwsProjectName = [];
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Time Grain",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.timegrain;
      },
    },
    {
      title: "Threshold",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return record.thresholds?.map((value) => {
          return <div>{value}%</div>;
        });
      },
    },
    {
      title: "Budget Level",
      key: "budgettype",
      dataIndex: "budgettype",
      align: "left",
    },
    {
      title: "Max Amount",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.amount;
      },
    },

    {
      title: "Currency",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return record.unit;
      },
    },
    {
      title: "Emails",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => (
        <>
          {record.emails?.map((email) => {
            return <div>{email}</div>;
          })}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Row>
          <Col>
            <Popconfirm
              title="Are you sure to delete this Budget?"
              onConfirm={() => {
                deleteBudgetAlert(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Alert">
                <Button type="link" size="small">
                  <DeleteOutlined
                    loading={deleteLoading}
                    style={{ color: "red" }}
                  />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
          <Col>
            {record.budgettype === "Amazon EC2" ||
            record.budgettype === "Amazon Relational Database Service" ? (
              <Tooltip title="Add Alert Action">
                <Button type="link" size="small">
                  <>
                    <PlusCircleOutlined
                      style={{ color: "green" }}
                      onClick={() => showActionModal(record)}
                    />
                  </>
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </Col>
          {/* <Col>
            <Tooltip title="Edit Alert">
              <Button type="link" size="small">
                <EditOutlined
                  style={{ color: "#5046e5" }}
                  onClick={() => showModal(record)}
                />
              </Button>
            </Tooltip> */}
          {/* <Modal
              width={800}
              open={visible}
              onCancel={handleModalCancel}
              title="Edit ALert"
              style={{ top: 75 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <EditAwsBudget
                editRecord={editRecord}
                awsAccount={awsAccount}
                awsAccountId={awsAccountId}
                getBudgetAlert={getAwsBudgetAlert}
                handleModalCancel={handleModalCancel}
              />
            </Modal> */}
          {/* </Col> */}
        </Row>
      ),
    },
  ];

  const deleteBudgetAlert = async (data) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setDeleteLoading(true);
    let name = data.name;

    let response = await axios.delete(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgets?accountid=${awsAccountId}&budgetname=${name}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (response.status === 200) {
      message.success("Alert Deleted SuccessFully!");
      setDeleteLoading(false);
    }
    getAwsBudgetAlert(awsAccountId, accessToken);
  };

  const handleModalCancel = () => {
    setVisible(false);
    setButtonLoading(false);
  };
  const showModal = (record) => {
    setEditRecord(record);
    setVisible(true);
  };
  const showActionModal = async (record) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setAddAlertAction(record);
    setVisible(true);
    let initialRegion = [];
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/common/regions?cloudType=AWS`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    initialRegion = response.data.regions;
    initialRegionName = initialRegion[1].regionName;
    setRegion(initialRegionName);
    setRegions(response.data.regions);
  };

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      let initialAccountAws = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccountAws = resp.data;
      initialAwsProjectName = initialAccountAws[0].account_name;
      setAwsAccount(initialAwsProjectName);
      initialAccountIdAWS = initialAccountAws[0].account_id;
      setAwsAccountId(initialAccountIdAWS);
      setAwsAccounts(resp.data);
      setLoading(true);
      getAwsBudgetAlert(initialAccountIdAWS, accessToken);
    })();
  }, []);

  const getAwsBudgetAlert = async (accountId, accessToken) => {
    setLoading(true);
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgets?accountid=${accountId}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    setBudgetAlert(response.data);
    setLoading(false);
  };

  const handleAwsProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    let filteredDataAws = awsAccounts.filter((account) => {
      if (value === account.account_id) {
        setAwsAccount(account.account_name);
      }
    });
    setAwsAccountId(value);
    setLoading(true);
    getAwsBudgetAlert(value, accessToken);
  };

  const getAwsAlertActions = async (expanded, record) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setRowExpanded(expanded);
    setRowLoading(true);
    try {
      let response = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgetactions?budgetName=${record.name}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      setAwsAlertActions(response.data);
      setRowLoading(false);
    } catch (error) {
      console.log("Error in Alert", error);
    }
  };

  const handleRegionChange = async (value) => {
    setRegion(value);
    getInstanceList(value);
  };

  const getInstanceList = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setInstanceLoading(true);
    if (actionChange === "STOP_EC2_INSTANCES") {
      let response = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgetactions?ec2List=true&region=${value}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      console.log("EC2 Response", response.data);
      setInstances(response.data);
      setInstanceLoading(false);
    } else if (actionChange === "STOP_RDS_INSTANCES") {
      let response = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgetactions?rdsList=true&region=${value}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      console.log("RDS Response", response);
      setInstances(response.data);
      setInstanceLoading(false);
    }
  };

  let accountsOptions = awsAccounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  let regionOptions = regions.map((region) => {
    return (
      <Option name={region.regionName} value={region.regionCode}>
        {region.regionName}
      </Option>
    );
  });

  let instanceOptions = instances.map((instance) => {
    if (actionChange === "STOP_EC2_INSTANCES") {
      return (
        <Option name={instance.InstancesName} value={instance.Instancesid}>
          {instance.InstancesName}
        </Option>
      );
    } else if (actionChange === "STOP_RDS_INSTANCES") {
      return (
        <Option name={instance.DBInstance} value={instance.DBInstance}>
          {instance.DBInstance}
        </Option>
      );
    }
  });

  const expandedRowRender = (record, i) => {
    const columns = [
      {
        title: "Action Type",
        key: "actionsubtype",
        render: (record) => {
          return <Badge status="error" text={record.actionsubtype} />;
        },
      },
      {
        title: "Instances ID",
        key: "",
        render: (record) => (
          <>
            {record.instanceids?.map((instance) => {
              return <div>{instance}</div>;
            })}
          </>
        ),
      },
      {
        title: "Threshold",
        dataIndex: "",
        render: (record) => {
          return <div>{record.actionthreshold}%</div>;
        },
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
      },
      {
        title: "Emails",
        key: "",
        render: (record) => (
          <>
            {record.emails?.map((email) => {
              return <div>{email.Address}</div>;
            })}
          </>
        ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={awsAlertActions}
        loading={rowLoading}
        pagination={false}
        size="middle"
        rowKey="id"
      />
    );
  };
  const handleActionChange = (value) => {
    setActionChange(value);
  };

  const handleInstanceChange = (value) => {
    setInstanceChange(value);
  };

  const onFinish = async (event) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setButtonLoading(true);

    let object = {
      accountId: addAlertAction.accountid,
      thresholdValue: threshold,
      budgetName: addAlertAction.name,
      subActionType: actionChange,
      instancesIds: instanceChange,
      region: region,
      email: addAlertAction.emails,
    };

    let response = await axios.post(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgetactions`,
      object,
      { headers: { Authorization: "Bearer " + accessToken } }
    );

    if (response.data.statusCode === 200) {
      setButtonLoading(false);
      handleModalCancel();
      getAwsBudgetAlert(addAlertAction.accountid, accessToken);
      message.success("Alert action created successfully");
    } else {
      setButtonLoading(false);
      handleModalCancel();
      message.error("Can't create alert action!!");
    }
  };

  return (
    <div className="my-4 cloud-charts">
      <div className="card">
        <div className="card-body p-5">
          <div className="">
            <Row>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <h2 className="m-b-20">AWS Budget Alert</h2>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div className="float-end">
                  <AddAwsBudgetAlert
                    accountId={awsAccountId}
                    accountName={awsAccount}
                    getAwsBudgetAlert={getAwsBudgetAlert}
                    budgetAlert={budgetAlert}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <Select
                  value={awsAccount}
                  mode="awsAccount"
                  className="selectProjectGCP"
                  placeholder="Select Account"
                  onChange={handleAwsProjectChange}
                  showArrow
                  clearIcon
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {accountsOptions}
                </Select>
              </Col>
            </Row>
          </div>
          <Table
            className="tableaws"
            columns={columns}
            dataSource={budgetAlert}
            loading={loading}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ["0"],
              onExpand: getAwsAlertActions,
            }}
            rowKey="id"
          />
          <Modal
            width={600}
            open={visible}
            onCancel={handleModalCancel}
            title="Add Alert Actions"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            {addAlertAction?.thresholds?.map((value) => {
              return (
                <div>
                  <Card>
                    <label>Threshold : {value}% </label>
                    <Collapse
                      size="small"
                      onChange={() => {
                        setThreshold(value);
                      }}
                    >
                      <Panel
                        header={
                          <Button className="float-end">Add Action</Button>
                        }
                      >
                        <Form
                          ref={formRef}
                          name="dynamic_form_nest_item"
                          onFinish={onFinish}
                          style={{
                            maxWidth: 550,
                          }}
                        >
                          <Form.Item>
                            <Select
                              placeholder="Select Action Type"
                              onChange={handleActionChange}
                            >
                              {addAlertAction.budgettype ===
                              "Amazon Relational Database Service" ? (
                                <Option value="STOP_RDS_INSTANCES">
                                  STOP RDS
                                </Option>
                              ) : (
                                <Option value="STOP_EC2_INSTANCES">
                                  STOP EC2
                                </Option>
                              )}
                            </Select>
                          </Form.Item>
                          <Form.Item>
                            <Select
                              placeholder="Select Region"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={handleRegionChange}
                            >
                              {regionOptions}
                            </Select>
                          </Form.Item>

                          <Form.Item>
                            <Select
                              mode="multiple"
                              placeholder="Select Instances"
                              loading={instanceLoading}
                              onChange={handleInstanceChange}
                            >
                              {instanceOptions}
                            </Select>
                          </Form.Item>
                          <Form.Item className="float-end mt-5">
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="ok-modal"
                              loading={buttonLoading}
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Form>
                      </Panel>
                    </Collapse>
                  </Card>
                  <br />
                </div>
              );
            })}
          </Modal>
        </div>
      </div>
    </div>
  );
}
