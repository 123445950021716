import React, { Component } from "react";
import {
  Button,
  Space,
  Table,
  Input,
  Row,
  Col,
  Spin,
  Popconfirm,
  Tooltip,
  Checkbox,
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons/lib/icons";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import {
  getAccountsLimit,
  deleteAccountLimit,
} from "../../../actions/userAction";
import EditResourceAlertList from "./EditResourceAlertList";
import moment from "moment";
import { Auth } from "aws-amplify";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 34,
    }}
    spin
  />
);
class ResourceWiseAlertList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      filteredInfo: {},
      sortedInfo: {},
      loading: true,
      data: [],
    };
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({ filteredInfo: filters, sortedInfo: sorter });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {" "}
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.getAccountsLimit(accessToken);
    try {
      let resp = await axios.get(
        "https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/billingnotificationbyresource",
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      if (resp.data) {
        this.setState({ loading: false });
      }
      this.setState({ data: resp.data.body });
    } catch (error) {
      console.log("fetch data failed", error);
    }
  }

  deleteLimit = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.props.deleteAccountLimit(id, accessToken);
  };
  render() {
    const columns = [
      {
        title: "Cloud",
        dataIndex: "cloud_name",
        key: "cloud_name",
        // filters: [
        //   { text: "AWS", value: "AWS" },
        //   { text: "Azure", value: "Azure" },
        //   { text: "GCP", value: "GCP" },
        // ],
        // filteredValue: this.state.filteredInfo.cloud_name || null,
        // onFilter: (value, record) => record.cloud_name.includes(value),
      },
      {
        title: "Resource Name",
        dataIndex: "resource_name",
        key: "resource_name",
      },

      {
        title: "Service ID",
        dataIndex: "service_id",
        key: "service_id",
      },
      {
        title: "Email(s)",
        dataIndex: "notification_emails",
        key: "notification_emails",
      },
      { title: "Limit", dataIndex: "limit", key: "limit" },
      {
        title: "Date",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (record) => <>{moment(record).format("MMM Do YYYY, h:mm a")}</>,
      },
      {
        title: "Email",
        dataIndex: "",
        key: "",
        render: (record) => (
          <>
            {record.email_alert === true ? (
              <Checkbox defaultChecked disabled />
            ) : (
              <Checkbox disabled />
            )}
          </>
        ),
      },
      {
        title: "Whatsapp",
        dataIndex: "",
        key: "",
        render: (record) => (
          <>
            {record.whatsapp_alert === true ? (
              <Checkbox defaultChecked disabled />
            ) : (
              <Checkbox disabled />
            )}
          </>
        ),
      },
      {
        title: "Call",
        dataIndex: "",
        key: "",
        render: (record) => (
          <>
            {record.ivrs_alert === true ? (
              <Checkbox defaultChecked disabled />
            ) : (
              <Checkbox disabled />
            )}
          </>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (record) => (
          <Row gutter={[0, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditResourceAlertList alertDetails={record} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Popconfirm
                title="Are you sure to delete this Limit?"
                // onConfirm={() => this.deleteLimit(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" size="small">
                  <Tooltip title="Delete Alert Limit">
                    <DeleteOutlined />
                  </Tooltip>
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ),
      },
    ];
    return (
      <div className="my-4 cloud-charts">
        <div className="card">
          <div className="card-body p-5">
            <div className="d-flex flex-row my-2">
              <h2 className="m-b-20">Resource Wise Alert</h2>
            </div>
            {this.state.data.length ? (
              <Table
                dataSource={this.state.data}
                columns={columns}
                pagination={{ pageSize: 3 }}
              />
            ) : (
              <div className="spinLoader">
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  limits: state.users.accLimitList,
});
export default connect(mapStateToProps, {
  getAccountsLimit,
  deleteAccountLimit,
})(ResourceWiseAlertList);
