import React, { useState, useEffect } from "react";
import { Line, G2 } from "@ant-design/plots";
import { each, findIndex } from "@antv/util";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Row, Col, Tooltip } from "antd";
import { Auth } from "aws-amplify";

export const GraphDashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      asyncFetch(accessToken);
    })();
  }, []);

  const getLast12Month = () => {
    const monthsShortNames = [];
    const currentDate = new Date();

    for (let i = 0; i < 12; i++) {
      const month = currentDate.getMonth();
      const shortNames = currentDate.toLocaleString("default", {
        month: "short",
      });

      monthsShortNames.push(shortNames.toUpperCase());

      currentDate.setMonth(month - 1);
    }

    return monthsShortNames.reverse();
  };

  function sortByMonth(arr) {
    var months = getLast12Month();
    let sortedData = arr.sort(function (a, b) {
      return months.indexOf(a.month) - months.indexOf(b.month);
    });
    setData(sortedData);
  }
  const asyncFetch = async (accessToken) => {
    fetch(
      "https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/dashboard/allClouds",
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((json) => {
        for (let i = 0; i < json.length; i++) {
          json[i].value = parseFloat(json[i].value);
        }
        sortByMonth(json);
      })
      .catch((error) => {
        console.log("Fetch data failed", error);
      });
  };
  const { InteractionAction, registerInteraction, registerAction } = G2;
  G2.registerShape("point", "custom-point", {
    draw(cfg, container) {
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      group.addShape("circle", {
        name: "outer-point",
        attrs: {
          x: point.x,
          y: point.y,
          fill: cfg.color || "red",
          opacity: 0.5,
          r: 6,
        },
      });
      group.addShape("circle", {
        name: "inner-point",
        attrs: {
          x: point.x,
          y: point.y,
          fill: cfg.color || "red",
          opacity: 1,
          r: 2,
        },
      });
      return group;
    },
  });

  class CustomMarkerAction extends InteractionAction {
    active() {
      const view = this.getView();
      const evt = this.context.event;

      if (evt.data) {
        const { items } = evt.data;
        const pointGeometries = view.geometries.filter(
          (geom) => geom.type === "point"
        );
        each(pointGeometries, (pointGeometry) => {
          each(pointGeometry.elements, (pointElement, idx) => {
            const active =
              findIndex(items, (item) => item.data === pointElement.data) !==
              -1;
            const [point0, point1] = pointElement.shape.getChildren();

            if (active) {
              point0.animate(
                {
                  r: 10,
                  opacity: 0.2,
                },
                {
                  duration: 1800,
                  easing: "easeLinear",
                  repeat: true,
                }
              ); // inner-circle

              point1.animate(
                {
                  r: 6,
                  opacity: 0.4,
                },
                {
                  duration: 800,
                  easing: "easeLinear",
                  repeat: true,
                }
              );
            } else {
              this.resetElementState(pointElement);
            }
          });
        });
      }
    }

    reset() {
      const view = this.getView();
      const points = view.geometries.filter((geom) => geom.type === "point");
      each(points, (point) => {
        each(point.elements, (pointElement) => {
          this.resetElementState(pointElement);
        });
      });
    }

    resetElementState(element) {
      const [point0, point1] = element.shape.getChildren();
      point0.stopAnimate();
      point1.stopAnimate();
      const { r, opacity } = point0.get("attrs");
      point0.attr({
        r,
        opacity,
      });
      const { r: r1, opacity: opacity1 } = point1.get("attrs");
      point1.attr({
        r: r1,
        opacity: opacity1,
      });
    }

    getView() {
      return this.context.view;
    }
  }

  registerAction("custom-marker-action", CustomMarkerAction);
  registerInteraction("custom-marker-interaction", {
    start: [
      {
        trigger: "tooltip:show",
        action: "custom-marker-action:active",
      },
    ],
    end: [
      {
        trigger: "tooltip:hide",
        action: "custom-marker-action:reset",
      },
    ],
  });
  const config = {
    data,
    xField: "month",
    yField: "value",
    seriesField: "category",
    xAxis: {
      label: {
        style: {
          fill: "black",
          opacity: 0.6,
        },
      },
    },
    theme: {
      styleSheet: {
        fontFamily: "Poppins",
      },
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: "black",
            strokeOpacity: 0.1,
          },
        },
      },
      label: {
        style: {
          fill: "black",
          opacity: 0.6,
        },
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#f08804", "#d73263", "#0078d4"],
    smooth: true,
    legend: false,
    autoFit: true,
    point: {
      size: 3,
      shape: "custom-point",
      style: {
        fill: "black",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
  };
  const headers = [
    { label: "Cloud", key: "category" },
    { label: "Cost", key: "value" },
    { label: "Month", key: "month" },
  ];
  return (
    <div className="container-dash-g card">
      <div className="dash-graph-line card-body">
        <Row gutter={[8, 8]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <span className="name-g ">Last 12 months billings</span>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <CSVLink
              className="float-end btn-dwn"
              data={data}
              headers={headers}
              filename={"Clouds_Total_Monthly_Billings"}
            >
              <Tooltip title="Export to csv">
                <div className="download-btn">
                  <Button size="large" type="text">
                    <DownloadOutlined className="text-black " />
                  </Button>
                </div>
              </Tooltip>
            </CSVLink>
          </Col>
        </Row>
        <div className="graph-div ">
          <Line {...config} />
        </div>
      </div>
    </div>
  );
};
