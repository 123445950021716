import React, { useState, useEffect, useRef } from "react";
import {
  Empty,
  Button,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Spin,
  Select,
} from "antd";
import { Column } from "@ant-design/plots";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";
const { Option } = Select;
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

export const GetMonthWiseDetailsBar = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };
  const [data, setData] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(month, Currency, accessToken);
    })();
  }, [Currency]);

  const asyncFetch = async (month, Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/monthscost?month=${month}&currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data.data) setLoading(false);
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) {
          filterData.cost = parseInt(filterData.cost);
          return filterData;
        }
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const config = {
    data,
    xField: "project_name",
    yField: "cost",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    scrollbar: {
      type: "horizontal",
    },
    minColumnWidth: 20,
    maxColumnWidth: 20,
  };
  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, currency, accessToken);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const headers = [
    { label: "Name", key: "project_name" },
    { label: "Cost", key: "cost" },
    { label: "Month", key: "month" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );

  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Projects Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">Month Wise</h5>
            </div>
            <Row gutter={[8, 8]} className="my-3">
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <DatePicker
                  allowClear={false}
                  disabledDate={disabledDate}
                  onChange={handleMonthChange}
                  defaultValue={moment()}
                  format={monthFormat}
                  picker="month"
                />{" "}
              </Col>
              <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                <CSVLink
                  className="float-end btn-dwn"
                  data={data}
                  headers={headers}
                  filename={
                    exportFileType.GCP_TYPE + "Project Billing_MonthWise"
                  }
                >
                  <Tooltip title="Export to csv">
                    <div className="download-btn">
                      <Button size="large" type="text">
                        <FileExcelOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </CSVLink>
                <Tooltip title="Export to PDF">
                  <div className="download-btn float-end aws-sb-btn3">
                    <Button size="large" type="text" onClick={printDocument}>
                      <FilePdfOutlined />
                    </Button>
                  </div>
                </Tooltip>
              </Col>
            </Row>
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return <Column {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
