import React, { useState, useEffect, useRef } from "react";
import {
  Empty,
  Row,
  Col,
  DatePicker,
  Button,
  Tooltip,
  Spin,
  Table,
  Input,
  Space,
} from "antd";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../util/constants";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Highlighter from "react-highlight-words";

import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";

const weekFormat = "YYYY/MM/DD";
const customWeekStartEndFormat = (value) =>
  `${moment(value).startOf("week").format(weekFormat)} To ${moment(value)
    .endOf("week")
    .format(weekFormat)}`;
const defaultStartWeek = moment(new Date()).startOf("week").format(weekFormat);
const defaultEndWeek = moment(new Date()).endOf("week").format(weekFormat);
var initialWeek = defaultStartWeek + " To " + defaultEndWeek;
var initialCurrency = "INR";

export const GetWeeklyBillingBarTable = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");
  const [week, setWeek] = useState(initialWeek);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(initialWeek, Currency, accessToken);
    })();
  }, [Currency]);

  const asyncFetch = async (date, Currency, accessToken) => {
    try {
      const dateArray = date.split(" To ");
      let startDate = dateArray[0];
      let endDate = dateArray[1];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/weekly?startDate=${startDate}&endDate=${endDate}&currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.total_exact > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const onChange = async (date, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setWeek(dateString);
    asyncFetch(dateString, currency, accessToken);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const dataSource = data;

  const columns = [
    {
      title: "Project",
      dataIndex: "project_name",
      key: "project_name",
      width: "50%",
      sortDirections: ["ascend"],
      sorter: (a, b) => a.project_name - b.project_name,
      ...getColumnSearchProps("project_name"),
    },
    {
      title: "Date",
      dataIndex: "value",
      key: "value",
    },
    {
      title: currency === "USD" ? "Total Cost($)" : "Total Cost(₹)",
      dataIndex: "total_exact",
      key: "total_exact",
    },
  ];

  const headers = [
    { label: "Name", key: "project_name" },
    { label: "Cost", key: "total_exact" },
    { label: "Date", key: "value" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Weekly Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">All Projects</h5>
            </div>

            <Row gutter={[8, 8]} className="my-3">
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <DatePicker
                  allowClear={false}
                  disabledDate={disabledDate}
                  defaultValue={moment()}
                  format={customWeekStartEndFormat}
                  picker="week"
                  onChange={onChange}
                  style={{ width: "228px" }}
                />
              </Col>
              <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                <CSVLink
                  className="float-end btn-dwn"
                  data={data}
                  headers={headers}
                  filename={
                    exportFileType.GCP_TYPE + "Projects Weekly Billings"
                  }
                >
                  <Tooltip title="Export to csv">
                    <div className="download-btn">
                      <Button size="large" type="text">
                        <FileExcelOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </CSVLink>

                <Tooltip title="Export to PDF">
                  <div className="download-btn float-end aws-sb-btn3">
                    <Button size="large" type="text" onClick={printDocument}>
                      <FilePdfOutlined />
                    </Button>
                  </div>
                </Tooltip>
              </Col>
            </Row>
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return (
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    scroll={{ y: 300 }}
                  />
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
