import React, { useState, useEffect, useRef } from "react";
import { Empty, Button, Tooltip, Row, Col, Spin, Select } from "antd";
import { Column } from "@ant-design/plots";
import axios from "axios";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";

const { Option } = Select;
var initialCurrency = "INR";

export const GetTotalMonthlyBar = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currency, setCurrency] = useState("INR");

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(Currency, accessToken);
    })();
  }, [Currency]);

  const asyncFetch = async (Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/totalmonthly?currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data) setLoading(false);
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) {
          filterData.cost = parseInt(filterData.cost);
          return filterData;
        }
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };

  const config = {
    data,
    xField: "month",
    yField: "cost",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    scrollbar: {
      type: "horizontal",
    },
    minColumnWidth: 20,
    maxColumnWidth: 20,
  };

  const handleCurrencyChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setCurrency(value);
    asyncFetch(value, accessToken);
  };

  const headers = [
    { label: "Month", key: "month" },
    { label: "Cost", key: "cost" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );

  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">All Project Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">Monthly</h5>
            </div>
            <Row gutter={[8, 8]} className="my-3">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CSVLink
                  className="float-end btn-dwn"
                  data={data}
                  headers={headers}
                  filename={exportFileType.GCP_TYPE + "Total Billings_Montly"}
                >
                  <Tooltip title="Export to csv">
                    <div className="download-btn">
                      <Button size="large" type="text">
                        <FileExcelOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </CSVLink>
                <Tooltip title="Export to PDF">
                  <div className="download-btn float-end aws-sb-btn3">
                    <Button size="large" type="text" onClick={printDocument}>
                      <FilePdfOutlined />
                    </Button>
                  </div>
                </Tooltip>
              </Col>
            </Row>
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else {
                return <Column {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
