import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Tooltip,
  Popconfirm,
  Button,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons/lib/icons";
import AddGcpBudgetAlert from "./AddGcpBudgetAlert";

const { Option } = Select;
let initialAccountId;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

export default function GcpBudgetAlert() {
  const [month, setMonth] = useState(initalMonth);
  const [budgetAlert, setBudgetAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: "Name",
      key: "displayName",
      dataIndex: "displayName",
    },
    {
      title: "Time Grain",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.budgetFilter.calendarPeriod;
      },
    },
    {
      title: "Max Amount",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.amount.specifiedAmount.units;
      },
    },

    {
      title: "Currency",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return record.amount.specifiedAmount.currencyCode;
      },
    },
    {
      title: "Threshold",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => (
        <>
          {record.thresholdRules?.map((threshold) => {
            return <div>{threshold.thresholdPercent * 100}%</div>;
          })}
        </>
      ),
    },
    {
      title: "Emails",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => (
        <>
          {record.notificationsRule.monitoringNotificationChannels?.map(
            (email) => {
              return <div>{email}</div>;
            }
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Row>
          <Col>
            <Popconfirm
              title="Are you sure to delete this Budget?"
              onConfirm={() => {
                deleteGCPBudgetAlert(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Alert">
                <Button type="link" size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
          {/* <Col>
            <Tooltip title="Edit Alert">
              <Button type="link" size="small">
                <EditOutlined
                  style={{ color: "#5046e5" }}
                  onClick={() => showModal(record)}
                />
              </Button>
            </Tooltip>
            <Modal
              width={800}
              open={visible}
              onCancel={handleModalCancel}
              title="Edit ALert"
              style={{ top: 75 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            > */}
          {/* <EditAwsBudget
                editRecord={editRecord}
                awsAccount={awsAccount}
                awsAccountId={awsAccountId}
                getBudgetAlert={getAwsBudgetAlert}
                handleModalCancel={handleModalCancel}
              /> */}
          {/* </Modal>
          </Col> */}
        </Row>
      ),
    },
  ];

  const handleModalCancel = () => {
    setVisible(false);
  };
  const showModal = (record) => {
    // setEditRecord(record);
    setVisible(true);
  };

  const deleteGCPBudgetAlert = async (data) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    let response = await axios.delete(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/budgets?budgetId=${data.name}&budgetName=${data.displayName}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    if (response.status === 200) {
      message.success("Alert Deleted Successfully!!");
    }
    getGcpBudgetAlert();
  };

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      getGcpBudgetAlert();
    })();
  }, []);

  const getGcpBudgetAlert = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    let resp = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/budgets?emails=undefined`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    setBudgetAlert(resp.data);
    setLoading(false);
  };
  return (
    <div className="my-4 cloud-charts">
      <div className="card">
        <div className="card-body p-5" style={{ height: "470px" }}>
          <div className="">
            <Row>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <h2 className="m-b-20">GCP Budget Alert</h2>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div className="float-end">
                  <AddGcpBudgetAlert
                    getGcpBudgetAlert={getGcpBudgetAlert}
                    budgetAlert={budgetAlert}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Table
            className="tableaws"
            columns={columns}
            dataSource={budgetAlert}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
