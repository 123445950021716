import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Select,
  Button,
  Form,
  Input,
  Tooltip,
  DatePicker,
  message,
  Space,
  Tag,
  Radio,
} from "antd";
import moment from "moment";
import { Auth } from "aws-amplify";
import axios from "axios";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
var initialAwsProjectName = [];
const { Option } = Select;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let initialAccountIdAWS;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

export default class AddAwsBudgetAlert extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      actionVisible: false,
      awsRecord: "",
      budgetName: "",
      budgetsPercent: [],
      start: "",
      end: "",
      month: initalMonth,
      items: [],
      value: "",
      value2: "",
      emailError: null,
      errors: {},
      awsAccountId: "",
      awsAccount: "",
      awsAccounts: [],
      timeGrain: "MONTHLY",
      index: "",
      indexNumber: {},
      newBudget: [],
      radioValue: "Account Level",
      serviceLevel: [],
    };
    this.onFinish = this.onFinish.bind(this);
  }
  initialAwsProjectName = [];

  componentDidMount() {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let initialAccountAws = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccountAws = resp.data;
      initialAwsProjectName = initialAccountAws[0].account_name;
      this.setState({ awsAccount: initialAwsProjectName });
      initialAccountIdAWS = initialAccountAws[0].account_id;
      this.setState({ awsAccountId: initialAccountIdAWS });
      this.setState({ awsAccounts: resp.data });
    })();
  }

  //Handle Select AWS Project Change
  handleAwsProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let filteredDataAws = this.state.awsAccounts.filter((account) => {
      if (value === account.account_id) {
        this.setState({ awsAccount: account.account_name });
      }
    });
    this.setState({ awsAccountId: value });
  };

  //Get AWS Budget Alert
  getAwsBudgetAlert = (accessToken) => {
    this.props.getAwsBudgetAlert(this.state.awsAccountId, accessToken);
  };

  //Show Modal
  showModal = () => {
    this.setState({
      awsAccountId: this.props.accountId,
      visible: true,
      actionVisible: false,
    });
  };

  //Modal Cancel
  handleModalCancel = () => {
    this.setState({
      visible: false,
      items: [],
      loading: false,
      actionVisible: false,
      newBudget: [],
    });
  };

  //Handle Change Date
  handleChangeDate = (range) => {
    const valueOfInput1 = range[0].format("YYYY-MM-DD");
    const valueOfInput2 = range[1].format("YYYY-MM-DD");
    String.prototype.replaceAt = function (index, replacement) {
      if (index >= this.length) {
        return this.valueOf();
      }
      var chars = this.split("");
      chars[index] = replacement;
      return chars.join("");
    };
    var str = valueOfInput1;
    str = str.replaceAt(8, "0");
    str = str.replaceAt(9, "1");

    var str2 = valueOfInput2;
    str2 = str2.replaceAt(8, "3");
    str2 = str2.replaceAt(9, "0");

    this.setState({ start: str, end: str2 });
  };

  //Handle Change Percentage
  handleChangePercent = (evt, index) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
    const amountPercentage = (this.state.limit / 100) * evt.target.value;
    this.setState({ amount: amountPercentage });
    let tempArray = this.state.indexNumber;
    tempArray[index] = amountPercentage;
    this.setState({ indexNumber: tempArray });
  };

  //Handle Change
  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
      emailError: null,
    });
  };

  //On Radio Change
  onRadioChange = (event) => {
    this.setState({
      radioValue: event.target.value,
    });
  };

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
        });
      }
    }
  };

  //Handle Email Delete
  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  //Email Paste
  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  isValid(email) {
    let emailError = null;

    if (this.isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    if (emailError) {
      this.setState({ emailError });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  //On Click Submit
  async onFinish(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loading: true });

    {
      event.budgetsPercent.map((percent) => {
        this.state.newBudget.push(percent.budgetPercentage);
      });
    }

    let flag = 1;
    this.props.budgetAlert.forEach((row) => {
      if (row.name === this.state.budgetName) {
        flag = 0;
      }
    });
    if (flag === 0) {
      message.error("Budget Name Already Exist!");
      this.setState({ loading: false });
    } else if (this.state.radioValue === "Service Level") {
      let obj = {
        accountId: this.state.awsAccountId,
        budgetName: this.state.budgetName,
        startDate: this.state.start,
        endDate: this.state.end,
        timeUnit: this.state.timeGrain,
        budgetAmount: this.state.limit,
        notificationsEmails: this.state.items,
        budgetsPercent: this.state.newBudget,
        budgetType: this.state.serviceLevel,
        services: this.state.serviceLevel,
      };
      let response = await axios.post(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgets`,
        obj,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (response.data.statusCode === 200) {
        message.success("AWS Alert Created SuccessFully!");
        this.setState({ loading: false, visible: false, actionVisible: true });
      }
      this.getAwsBudgetAlert(accessToken);
    } else {
      let obj = {
        accountId: this.state.awsAccountId,
        budgetName: this.state.budgetName,
        startDate: this.state.start,
        endDate: this.state.end,
        timeUnit: this.state.timeGrain,
        budgetAmount: this.state.limit,
        notificationsEmails: this.state.items,
        budgetsPercent: this.state.newBudget,
        budgetType: this.state.radioValue,
        services: this.state.serviceLevel,
      };
      let response = await axios.post(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/budgets`,
        obj,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (response.status === 200) {
        message.success("AWS Alert Created SuccessFully!");
        this.setState({ loading: false, visible: false, actionVisible: true });
      }
      this.getAwsBudgetAlert(accessToken);
    }
  }

  //Handle Timegrain Change
  handleTimeGrainChange = (value) => {
    this.setState({ timeGrain: value });
  };

  //Handle Service Level Change
  handleServiceLevelChange = (value) => {
    this.setState({ serviceLevel: value });
  };

  render(
    accountsOptions = this.state.awsAccounts.map((account) => {
      return (
        <Option name={account.account_name} value={account.account_id}>
          {account.account_name}
        </Option>
      );
    })
  ) {
    const { visible, loading } = this.state;
    const { props } = this.props;
    return (
      <span>
        <div>
          <>
            <Tooltip>
              <Button onClick={this.showModal} className="float-end">
                Add Budget Alert
              </Button>
            </Tooltip>
          </>

          <Modal
            width={800}
            open={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Add Alert AWS"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              ref={this.formRef}
              name="add-SBU"
              className="add-SBU"
              onFinish={this.onFinish}
              initialValues={{
                remember: true,
                budgetsPercent: [""],
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Select Account",
                      },
                    ]}
                  >
                    <Select
                      value={this.state.awsAccount}
                      mode="awsAccount"
                      placeholder="Select Account"
                      onChange={this.handleAwsProjectChange}
                      showArrow
                      clearIcon
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accountsOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Select
                      defaultValue="MONTHLY"
                      onChange={this.handleTimeGrainChange}
                      options={[
                        {
                          value: "MONTHLY",
                          label: "MONTHLY",
                        },
                        {
                          value: "QUARTERLY",
                          label: "QUARTERLY",
                        },
                        {
                          value: "ANNUALLY",
                          label: "ANNUALLY",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="budgetName"
                    rules={[
                      {
                        required: true,
                        message: "Please fill budget name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Budget Name"
                      onChange={(e) => {
                        this.setState({ budgetName: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="startEndDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select start and end date",
                      },
                    ]}
                  >
                    <RangePicker
                      style={{ width: " -webkit-fill-available" }}
                      format="YYYY-MM"
                      picker="month"
                      name="date"
                      id="date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={this.handleChangeDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Radio.Group
                    onChange={this.onRadioChange}
                    value={this.state.radioValue}
                  >
                    <Radio value="Account Level">Account Level Budget</Radio>
                    <Radio value="Service Level">Service Level Budget</Radio>
                  </Radio.Group>
                </Col>

                {this.state.radioValue === "Service Level" ? (
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item>
                        <Select
                          placeholder="Select Service Level"
                          onChange={this.handleServiceLevelChange}
                          options={[
                            {
                              value: "Amazon EC2",
                              label: "Amazon EC2",
                            },
                            {
                              value: "Amazon Relational Database Service",
                              label: "Amazon Relational Database Service",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item>
                        <Select
                          disabled
                          placeholder="Select Service Level"
                          onChange={this.handleServiceLevelChange}
                          options={[
                            {
                              value: "Amazon EC2",
                              label: "Amazon EC2",
                            },
                            {
                              value: "Amazon Redshift",
                              label: "Amazon Redshift",
                            },
                            {
                              value: "Amazon Relational Database Service",
                              label: "Amazon Relational Database Service",
                            },
                            {
                              value: "Amazon ElastiCache",
                              label: "Amazon ElastiCache",
                            },
                            {
                              value: "Amazon OpenSearch Service",
                              label: "Amazon OpenSearch Service",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="limit"
                    rules={[
                      {
                        required: true,
                        message: "Please add max limit",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="$"
                      placeholder="Max Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        this.setState({ limit: parseInt(e.target.value) });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.List name="budgetsPercent">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row>
                            <Space key={field.key} align="baseline">
                              <Col>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.area !== curValues.area ||
                                    prevValues.budgetsPercent !==
                                      curValues.budgetsPercent
                                  }
                                >
                                  {() => (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "budgetPercentage"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please add budget percentage",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Budget Percentage"
                                        addonAfter="%"
                                        name="budgetPercentage"
                                        value={this.state.budgetsPercent}
                                        onChange={(event) =>
                                          this.handleChangePercent(event, index)
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "amount"]}
                                >
                                  <div>
                                    <Tag
                                      className="tagbudget"
                                      style={{
                                        margin: "0 0px 0 0 ",
                                        padding: "0 8px",
                                        fontSize: "14px ",
                                        lineHeight: "30px ",
                                        width: "138px",
                                      }}
                                    >
                                      Amount - {this.state.indexNumber[index]}
                                    </Tag>
                                  </div>
                                </Form.Item>
                              </Col>

                              <Col>
                                {index === 0 ? null : (
                                  <MinusCircleOutlined
                                    twoToneColor="red"
                                    style={{ fontSize: "18px" }}
                                    onClick={() => {
                                      remove(field.name);
                                      this.onRemove(index);
                                    }}
                                  />
                                )}
                              </Col>
                            </Space>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusCircleOutlined />}
                          >
                            Add Option
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {this.state.items.map((item) => (
                    <div className="tag-item mb-2" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                  <Input
                    name="value"
                    className={"input" + (this.state.emailError && "has-error")}
                    value={this.state.value}
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                  />
                  {this.state.emailError && (
                    <p className="error">{this.state.emailError}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>
                    {this.state.items.length !== 0 ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={loading}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled>Save</Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            width={800}
            open={this.actionVisible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Add Alert AWS"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              ref={this.formRef}
              name="add-SBU"
              className="add-SBU"
              onFinish={this.onFinish}
              initialValues={{
                remember: true,
                budgetsPercent: [""],
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Select Account",
                      },
                    ]}
                  >
                    <Select
                      value={this.state.awsAccount}
                      mode="awsAccount"
                      placeholder="Select Account"
                      onChange={this.handleAwsProjectChange}
                      showArrow
                      clearIcon
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accountsOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Select
                      defaultValue="MONTHLY"
                      onChange={this.handleTimeGrainChange}
                      options={[
                        {
                          value: "MONTHLY",
                          label: "MONTHLY",
                        },
                        {
                          value: "QUARTERLY",
                          label: "QUARTERLY",
                        },
                        {
                          value: "ANNUALLY",
                          label: "ANNUALLY",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="budgetName"
                    rules={[
                      {
                        required: true,
                        message: "Please fill budget name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Budget Name"
                      onChange={(e) => {
                        this.setState({ budgetName: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="startEndDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select start and end date",
                      },
                    ]}
                  >
                    <RangePicker
                      style={{ width: " -webkit-fill-available" }}
                      format="YYYY-MM"
                      picker="month"
                      name="date"
                      id="date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={this.handleChangeDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="limit"
                    rules={[
                      {
                        required: true,
                        message: "Please add max limit",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="$"
                      placeholder="Max Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        this.setState({ limit: parseInt(e.target.value) });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>

                    {this.state.items.length !== 0 ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={loading}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled>Save</Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      </span>
    );
  }
}
