import React from "react";
import UserListDetails from "./UserListDetails";
import { Row, Col } from "antd";

export default function UserList() {
  return (
    <div className="container dashboard statistics-card">
      <Row className="title-row">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="d-flex flex-row">
            <div className="pages-header">Users</div>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <UserListDetails />
        </Col>
      </Row>
    </div>
  );
}
