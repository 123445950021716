import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Row,
  Col,
  Empty,
  DatePicker,
  Tooltip,
  Button,
  Spin,
  Table,
} from "antd";
import { G2 } from "@ant-design/plots";
import moment from "moment";
import axios from "axios";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../util/constants";
import { Auth } from "aws-amplify";
import { FileExcelOutlined } from "@ant-design/icons/lib/icons";

let initialAccountId;
var initialCurrency = "USD";

const { Option } = Select;
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const G = G2.getEngine("canvas");
var initialProjectName = "";

export const GetMonthAndAccountWiseDetailsTable = ({ Currency }) => {
  const myRef = useRef(null);
  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [account, setAccountId] = useState();
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");

  const columns = [
    {
      title: "Project",
      dataIndex: "accountname",
      key: "accountname",
      width: "24%",
    },
    { title: "Month", dataIndex: "month", key: "month", width: "24%" },
    {
      title: "Service Name",
      dataIndex: "servicename",
      key: "servicename",
      width: "28%",
    },
    {
      title: currency === "USD" ? `Cost($) ` : `Cost(₹)`,
      dataIndex: "cost",
      key: "cost",
      width: "24%",
    },
  ];

  useEffect(() => {
    setLoading(true);
    setData([]);
  }, [Currency]);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      let initialAccount = [];
      fetch(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((json) => {
          initialAccount = json;
          initialAccountId = initialAccount[0].account_id;
          initialProjectName = initialAccount[0].account_name;
          setprojectName(initialProjectName);
          setAccountId(initialAccountId);
          setAccounts(json);
          setCurrency(Currency);
          asyncFetch(month, initialAccountId, Currency, accessToken);
        })
        .catch((error) => {
          console.log("fetch data failed", error);
        });
    })();
  }, [Currency]);
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const asyncFetch = async (month, accountId, Currency, accessToken) => {
    setLoading(true);

    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/accountscost?month=${month}&account=${accountId}&isServicewise=true&currency=${Currency}`,

        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data.data) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const config = {
    appendPadding: 10,
    data,
    angleField: "cost",
    colorField: "servicename",
    radius: 0.8,

    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 10,
            text: `${data.cost}`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 400,
          },
        });
        return group;
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
      {
        type: "element-selected",
      },
    ],
  };
  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, account, currency, accessToken);
  };
  const handleAccountChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setAccountId(value);
    asyncFetch(month, value, currency, accessToken);
  };

  let accountsOptions = accounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  const headers = [
    { label: "Service Name", key: "serviceName" },
    { label: "Cost", key: "cost" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "450px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Services Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">
                Account & Month Wise
              </h5>
            </div>
            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    onChange={handleMonthChange}
                    defaultValue={moment(initalMonth, monthFormat)}
                    format={monthFormat}
                    picker="month"
                  />
                </Col>

                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={project}
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleAccountChange}
                  >
                    {accountsOptions}
                  </Select>
                </Col>

                <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.AWS_TYPE +
                      "Services Billings_Month&AccWise"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                </Col>
              </Row>
            )}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length != 0) {
                return (
                  <Table
                    dataSource={data}
                    columns={columns}
                    scroll={{
                      y: 240,
                    }}
                    pagination={false}
                  />
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
