import React, { Component } from "react";
import { Row, Col } from "antd";
import cloudLimit from "../../../assests/img/cloud-limit.png";
import AddCloudLimit from "./AddCloudLimit";
import CloudLimitDetailsList from "./CloudLimitDetailsList";
import ResourceWiseAlertList from "./ResourceWiseAlertList";

export default class CloudLimitDashboard extends Component {
  render() {
    return (
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="d-flex flex-row">
              <div className="pages-header">Daily Cloud Limit Alert</div>
              <img
                style={{ margin: "6.5px 0 0 5px" }}
                src={cloudLimit}
                width={25}
                height={25}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <AddCloudLimit /> */}
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <CloudLimitDetailsList />
          </Col>
        </Row>
        {/* <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <ResourceWiseAlertList />
          </Col>
        </Row> */}
      </div>
    );
  }
}
