import React, { useState, useEffect, useRef } from "react";
import {
  Empty,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Tooltip,
  Spin,
  Table,
  Input,
  Space,
} from "antd";
import { G2 } from "@ant-design/plots";
import axios from "axios";
import moment from "moment";
import { CSVLink } from "react-csv";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../util/constants";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Highlighter from "react-highlight-words";

import { FileExcelOutlined } from "@ant-design/icons/lib/icons";

const G = G2.getEngine("canvas");
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  initalMonth = moment(new Date()).month() + "-" + moment(new Date()).year();
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
let initialAccountId;
const { Option } = Select;
var initialProjectName = "";
var initialCurrency = "INR";

export const GetMonthAndAccountWiseDetailsTable = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [account, setAccountId] = useState();
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      let initialAccount = [];

      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0].account_name;
      setprojectName(initialProjectName);
      initialAccountId = initialAccount[0].account_id;
      setAccountId(initialAccountId);
      setAccounts(resp.data);
      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(month, initialAccountId, Currency, accessToken);
    })();
  }, [Currency]);

  const asyncFetch = async (month, accountId, Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/allaccountscost?currency=${Currency}&monthYear=${month}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      if (resp.data) setLoading(false);
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "AccountName",
      dataIndex: "account_name",
      key: "account_name",
      sortDirections: ["ascend"],
      sorter: (a, b) => a.account_name - b.account_name,
      ...getColumnSearchProps("account_name"),
    },
    {
      title: "Month",
      dataIndex: "month_year",
      key: "month_year",
    },
    {
      title: Currency === "USD" ? "Total Cost($)" : "Total Cost(₹)",
      dataIndex: "cost",
      key: "cost",
    },
  ];

  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, account, Currency, accessToken);
  };

  const handleAccountChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setAccountId(value);
    asyncFetch(month, value, currency, accessToken);
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  let accountsOptions = accounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });
  const headers = [
    {
      label: "Account Id",
      key: "account_id",
    },
    { label: "Service Name", key: "service_name" },
    { label: "Cost", key: "cost" },
    { label: "Date", key: "date" },
    { label: "Currency", key: "currency" },
    { label: "MonthYear", key: "month_year" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Services Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">
                Account & Month Wise
              </h5>
            </div>

            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={19} sm={19} md={19} lg={19} xl={19}>
                  <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    onChange={handleMonthChange}
                    defaultValue={moment()}
                    format={monthFormat}
                    picker="month"
                  />
                </Col>

                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.AZURE_TYPE +
                      "Services Billings_Month&AccWise"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                </Col>
              </Row>
            )}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return (
                  <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 300 }}
                    pagination={false}
                  />
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
