import React, { useState, useRef } from "react";
import { Row, Col, Select } from "antd";
import azure from "../../../../assests/img/azure.png";
import { GetTotalMonthBillingsAzureTable } from "./GetTotalMonthBillingsAzureTable";
import { GetRegionWiseDetailsTable } from "./GetRegionWiseDetailsTable";
import { GetMonthAndAccountWiseDetailsTable } from "./GetMonthAndAccountWiseDetailsTable";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FilePdfOutlined } from "@ant-design/icons/lib/icons";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import { GetBillingsByAccountTable } from "./GetBillingsByAccountTable";

const { Option } = Select;

const AzureDataTable = () => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();

      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const currencies = ["USD", "INR"];

  const handleCurrencyChange = async (value) => {
    setCurrency(value);
  };

  let currencyOptions = currencies.map((cur) => {
    return (
      <Option name={cur} value={cur}>
        {cur}
      </Option>
    );
  });

  return (
    <div id="divToPrint" ref={myRef}>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={18} sm={18} md={18} lg={18} xl={18}>
            <div className="d-flex flex-row">
              <div className="pages-header">Microsoft Azure</div>
              <img
                style={{ margin: "6.5px 0 0 5px" }}
                src={azure}
                width={25}
                height={25}
              />
            </div>
          </Col>
          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
            {currency && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Select
                  style={{
                    width: "62%",
                    float: "right",
                  }}
                  defaultValue={currencies[0]}
                  showSearch
                  placeholder="Select Currency"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={handleCurrencyChange}
                >
                  {currencyOptions}
                </Select>
              </Col>
            )}
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Tooltip title="Export to PDF">
              <div className="download-btn float-end aws-sb-btn3">
                <Button size="large" type="text" onClick={printDocument}>
                  <FilePdfOutlined />
                </Button>
              </div>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GetBillingsByAccountTable Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetTotalMonthBillingsAzureTable Currency={currency} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <GetRegionWiseDetailsTable Currency={currency} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <GetMonthAndAccountWiseDetailsTable Currency={currency} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AzureDataTable;
