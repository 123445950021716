import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Input,
  Space,
  Carousel,
  Skeleton,
} from "antd";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import aws from "../../../assests/img/aws-card.png";
import gcp from "../../../assests/img/gcp.png";
import azure from "../../../assests/img/azure.png";
import { AWSDonut } from "../charts/dashboard-charts/AWSDonut";
import { AzureDonut } from "../charts/dashboard-charts/AzureDonut";
import { GCPDonut } from "../charts/dashboard-charts/GCPDonut";
import moment from "moment";
import { Auth } from "aws-amplify";

const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const { Column } = Table;

export default class AccountsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awsAccounts: [],
      gcpAccounts: [],
      azureAccounts: [],
      month: initalMonth,
      currency: ["USD"],
    };
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  async componentDidMount() {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.getAccounts(this.state.month, accessToken, this.state.currency);
  }
  getAccounts = async (month, accessToken, currency) => {
    let awsResp = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/accountscost?month=${month}&sum=true&account=null&currency=USD`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("aws response", awsResp);
    this.setState({
      awsAccounts: awsResp.data.data,
    });
    console.log("month======", month);
    console.log("Access token", accessToken);
    let gcpResp = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/monthscost?month=${month}&currency=${currency}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("gcp responsee", gcpResp);
    this.setState({
      gcpAccounts: gcpResp.data.data,
    });
    let azureResp = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}&currency=${currency}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("azure response", azureResp);
    this.setState({
      azureAccounts: azureResp.data,
    });
  };
  render() {
    const contentStyle = {
      height: "350px",
      color: "#fff",
      lineHeight: "350px",
      textAlign: "center",
    };

    return (
      <div className="list-accounts">
        <div className="card">
          <div className="card-body">
            <Carousel dotPosition="right">
              <div className="p-2">
                <Row gutter={[8, 48]} className="mt-4">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <h3 style={contentStyle}>
                      <div className="d-flex flex-row my-2">
                        <h2 className="m-b-20 text-secondary ">
                          Amazon Web Services
                        </h2>
                        <img
                          className="ms-2 mt-2"
                          src={aws}
                          width={25}
                          height={20}
                        />
                      </div>

                      {this.state.awsAccounts.length === 0 ? (
                        <Skeleton active />
                      ) : (
                        <Table
                          dataSource={this.state.awsAccounts}
                          bordered={false}
                          size="middle"
                          pagination={false}
                        >
                          <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                          />

                          <Column
                            title="Account Name"
                            dataIndex="accountName"
                            key="accountName"
                            {...this.getColumnSearchProps("account_name")}
                          />
                          <Column
                            title="Cost"
                            dataIndex="cost"
                            key="cost"
                            render={(value, item, index) => `$${item.cost}`}
                          />
                        </Table>
                      )}
                    </h3>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <AWSDonut />
                  </Col>
                </Row>
              </div>
              <div className="p-2">
                <Row gutter={[8, 48]} className="mt-4">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <h3 style={contentStyle}>
                      <div className="d-flex flex-row my-2">
                        <h2 className="m-b-20 text-secondary ">
                          Google Cloud Platform
                        </h2>
                        <img
                          className="ms-2"
                          src={gcp}
                          width={25}
                          height={25}
                        />
                      </div>

                      {this.state.gcpAccounts.length === 0 ? (
                        <Skeleton active />
                      ) : (
                        <Table
                          dataSource={this.state.gcpAccounts}
                          bordered={false}
                          size="middle"
                          pagination={false}
                        >
                          <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                          />

                          <Column
                            title="Project Name"
                            dataIndex="project_name"
                            key="project_name"
                            {...this.getColumnSearchProps("project_name")}
                          />
                          <Column
                            title="Cost"
                            dataIndex="cost"
                            key="cost"
                            render={(value, item, index) => `$${item.cost}`}
                          />
                        </Table>
                      )}
                    </h3>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <GCPDonut />
                  </Col>
                </Row>
              </div>
              <div className="p-2">
                <Row gutter={[8, 48]} className="mt-4">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <h3 style={contentStyle}>
                      <div className="d-flex flex-row my-2">
                        <h2 className="m-b-20 text-secondary ">
                          Microsoft Azure
                        </h2>
                        <img
                          className="ms-2 mt-1"
                          src={azure}
                          width={25}
                          height={20}
                        />
                      </div>
                      {this.state.azureAccounts.length === 0 ? (
                        <Skeleton active />
                      ) : (
                        <Table
                          dataSource={this.state.azureAccounts}
                          bordered={false}
                          size="middle"
                          pagination={false}
                        >
                          <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                          />

                          <Column
                            title="Account Name"
                            dataIndex="account_name"
                            key="account_name"
                            {...this.getColumnSearchProps("account_name")}
                          />
                          <Column
                            title="Cost"
                            dataIndex="cost"
                            key="cost"
                            render={(value, item, index) => `$${item.cost}`}
                          />
                        </Table>
                      )}
                    </h3>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <AzureDonut />
                  </Col>
                </Row>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
