import React, { Component } from "react";
import { Switch, Layout, Result } from "antd";
import Dashboard from "./dashboard/Dashboard";
import PropTypes from "prop-types";
import AWSDashboard from "./AWSDashboard";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import AzureDashboard from "./AzureDashboard";
import GCPDashboard from "./GCPDashboard";
import { withAuthenticator } from "@aws-amplify/ui-react";
import moment from "moment";
import { brand, routes } from "../../util/constants";
import CloudLimitDashboard from "./cloud limit/CloudLimitDashboard";
import { TagManagement } from "./TagManagement/TagManagement";
import UserList from "./UserList/UserList";
import { AWSDataTable } from "./tables/AWS/AWSDataTable";
import AzureDataTable from "./tables/AZURE/AzureDataTable";
import { GCPDataTable } from "./tables/GCP/GCPDataTable";
import BudgetAlert from "./budgetAlert/BudgetAlert";
const { Sider, Content, Footer } = Layout;

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    const Token = localStorage.getItem("token");
    let IsLoggedIn = true;
    if (Token === null) {
      IsLoggedIn = false;
    }
    this.state = {
      IsLoggedIn,
      collapsed: false,
      sideCollapsed: false,
      hideSwitch: false,
      viewToggleBtn: true,
    };
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      hideSwitch: true,
      sideCollapsed: false,
    });
    if (this.state.sideCollapsed) {
      this.setState({ sideCollapsed: false });
    }
  };
  onSwitchChange = (checked) => {
    this.setState({ sideCollapsed: checked });
    if (!this.state.sideCollapsed) {
      this.setState({ viewToggleBtn: false });
    }
  };
  handleMouseEnter = () => {
    this.setState({ viewToggleBtn: true });
  };
  handleMouseLeave = () => {
    this.setState({ viewToggleBtn: false });
  };
  renderPathName(pathname) {
    switch (pathname) {
      case routes.AWS_BILLINGS:
        return <AWSDashboard />;
      case routes.AZURE_BILLINGS:
        return <AzureDashboard />;
      case routes.GCP_BILLINGS:
        return <GCPDashboard />;
      case routes.AWS_DATA_TABLE:
        return <AWSDataTable />;
      case routes.AZURE_DATA_TABLE:
        return <AzureDataTable />;
      case routes.GCP_DATA_TABLE:
        return <GCPDataTable />;
      case routes.CLOUD_LIMIT:
        return <CloudLimitDashboard />;
      case routes.TAG_MANAGEMENT:
        return <TagManagement />;
      case routes.BUDGET_ALERT:
        return <BudgetAlert />;
      case routes.USER_LIST:
        return <UserList />;
      default:
        return <Dashboard />;
    }
  }
  render() {
    const { pathname } = this.props.location;
    return (
      <>
        <Layout className="admin-dash ">
          <Sider
            width={260}
            breakpoint="sm"
            collapsedWidth={this.state.sideCollapsed ? "80" : "0"}
            theme="light"
            trigger={null}
            collapsible
            collapsed={
              this.state.sideCollapsed
                ? this.state.sideCollapsed
                : this.state.collapsed
            }
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <div className={this.state.collapsed ? "display-switch" : ""}>
              <div
                className={
                  !this.state.sideCollapsed ? "toogle-off" : "toogle-on"
                }
              >
                <span
                  className={
                    this.state.sideCollapsed && !this.state.viewToggleBtn
                      ? "collapsed-switch-visible"
                      : ""
                  }
                >
                  <Switch
                    checked={this.state.sideCollapsed}
                    onChange={this.onSwitchChange}
                    size="small"
                  />
                </span>
              </div>
            </div>
            <SiderMenu
              history={this.props.history}
              user={this.props.user}
              collapsed={this.state.collapsed}
            ></SiderMenu>
          </Sider>
          <Layout className="site-layout">
            <HeaderAdmin
              toggle={this.toggle}
              collapsed={this.state.collapsed}
              user={this.props.user}
              signOut={this.props.signOut}
            />

            <Content
              style={{
                margin: "30px",
                minHeight: "100vh",
              }}
            >
              {this.renderPathName(pathname)}
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              {brand.NAME} ©{moment().year()} Created by EAST-Team
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}
AdminDashboard.propTypes = {
  location: PropTypes.object.isRequired,
};
export default withAuthenticator(AdminDashboard);
