import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Select,
  Button,
  Form,
  Input,
  Tooltip,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { Auth } from "aws-amplify";
import axios from "axios";

const { RangePicker } = DatePicker;
let initialAccountId;
var initialProjectName = [];
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
const { Option } = Select;

let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth == 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

class AddAzureAlert extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      azureRecord: "",
      budgetName: "",
      budgetsPercent: [],
      start: "",
      end: "",
      month: initalMonth,
      items: [],
      value: "",
      emailError: null,
      errors: {},
      azureAccountId: "",
      azureAccount: "",
      azureAccounts: [],
      timeGrain: "Monthly",
      index: "",
      indexNumber: {},
      newBudget: [],
    };
    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    (async () => {
      let accessToken = (await Auth.currentSession())

        .getAccessToken()
        .getJwtToken();
      // this.setState({ loading: true });
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${this.state.month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0]?.account_name;
      this.setState({ azureAccount: initialProjectName });
      initialAccountId = initialAccount[0]?.account_id;
      this.setState({ azureAccountId: initialAccountId });
      this.setState({ azureAccounts: resp.data });
    })();
  }
  handleAzureProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let filteredDataAzure = this.state.azureAccounts.filter((account) => {
      if (value == account.account_id) {
        this.setState({ azureAccount: account.account_name });
      }
    });
    this.setState({ azureAccountId: value });
  };

  showModal = () => {
    this.setState({ azureRecord: this.props.selectedServices[0] });
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false, items: [] });
  };

  handleChangeDate = (range) => {
    const valueOfInput1 = range[0].format("YYYY-MM-DD");
    const valueOfInput2 = range[1].format("YYYY-MM-DD");
    String.prototype.replaceAt = function (index, replacement) {
      if (index >= this.length) {
        return this.valueOf();
      }
      var chars = this.split("");
      chars[index] = replacement;
      return chars.join("");
    };
    var str = valueOfInput1;
    str = str.replaceAt(8, "0");
    str = str.replaceAt(9, "1");

    var str2 = valueOfInput2;
    str2 = str2.replaceAt(8, "3");
    str2 = str2.replaceAt(9, "0");

    this.setState({ start: str, end: str2 });
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
      emailError: null,
    });
  };
  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
        });
      }
    }
  };

  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  isValid(email) {
    let emailError = null;

    if (this.isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    if (emailError) {
      this.setState({ emailError });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  async onFinish(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loading: true });

    let flag = 1;
    this.props.allAzureAlertData.forEach((row) => {
      if (row.name === this.state.budgetName) {
        flag = 0;
      }
    });
    if (flag == 0) {
      message.error("Budget Name Already Exist!");
      this.setState({ loading: false });
    } else {
      let obj = {
        queryStringParameters: {
          accountId: this.state.azureAccountId,
          resourceGroupName: this.state.azureRecord.ResourceGroupName,
          budgetName: this.state.budgetName,
          startDate: this.state.start,
          endDate: this.state.end,
          timeGrain: this.state.timeGrain,
          budgetAmount: this.state.limit,
          notificationsEmails: this.state.items,
          budgetsPercent: event.budgetsPercent,
        },
      };

      let response = await axios.put(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets`,
        obj,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      this.setState({ loading: false, visible: false });
      if (response.data.statusCode === 200) {
        message.success("Azure Alert Created SuccessFully!");
      }
    }
  }

  handleTimeGrainChange = (value) => {
    this.setState({ timeGrain: value });
  };

  handleChangePercent = (evt, index) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };
  render(
    accountsOptions = this.state.azureAccounts.map((account) => {
      return (
        <Option name={account.account_name} value={account.account_id}>
          {account.account_name}
        </Option>
      );
    })
  ) {
    const { visible, loading } = this.state;
    const { props } = this.props;
    return (
      <span>
        <div>
          {this.props.cloudType === "Azure" ? (
            <>
              <Tooltip
                title={
                  this.props.buttonVisible ? "Please select a service" : ""
                }
              >
                <Button
                  disabled={!this.props.disabled}
                  onClick={this.showModal}
                  className="float-end"
                >
                  Add Alert
                </Button>
              </Tooltip>
            </>
          ) : (
            <></>
          )}

          <Modal
            width={800}
            open={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Add Alert Azure"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              ref={this.formRef}
              name="add-SBU"
              className="add-SBU"
              onFinish={this.onFinish}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Cloud Type : Azure</h4>
                </Col>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Row>
                    <Col>
                      <h4>Resource Name :</h4>
                    </Col>
                    <Col>
                      <h4
                        style={{
                          maxWidth: "150px",
                          minWidth: "0px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip
                          title={this.state.azureRecord?.ResourceName}
                          key={this.state.azureRecord?.ResourceName}
                        >
                          {this.state.azureRecord?.ResourceName}
                        </Tooltip>
                      </h4>
                    </Col>
                  </Row>
                </Col>

                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Resource Type : {this.state.azureRecord?.ServiceName}</h4>
                </Col>
              </Row>
              <br />
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Select
                      value={this.state.azureAccount}
                      mode="azureAccount"
                      placeholder="Select Account"
                      onChange={this.handleAzureProjectChange}
                      showArrow
                      clearIcon
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accountsOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Select
                      defaultValue="Monthly"
                      onChange={this.handleTimeGrainChange}
                      options={[
                        {
                          value: "Monthly",
                          label: "Monthly",
                        },
                        {
                          value: "Quarterly",
                          label: "Quarterly",
                        },
                        {
                          value: "Annually",
                          label: "Annually",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="budgetName">
                    <Input
                      placeholder="Budget Name"
                      onChange={(e) => {
                        this.setState({ budgetName: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="startEndDate">
                    <RangePicker
                      style={{ width: " -webkit-fill-available" }}
                      format="YYYY/MM"
                      picker="month"
                      name="date"
                      id="date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={this.handleChangeDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="limit">
                    <Input
                      addonBefore="$"
                      placeholder="max Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        this.setState({ limit: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="budgetsPercent">
                    <Input
                      addonAfter="%"
                      placeholder="Budget Percentage(Min 85)"
                      name="budgetsPercent"
                      type="number"
                      min={85}
                      max={100}
                      value={this.state.budgetsPercent}
                      onChange={(event) => this.handleChangePercent(event)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 0]}></Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {this.state.items.map((item) => (
                    <div className="tag-item mb-2" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}

                  <Input
                    name="value"
                    className={"input" + (this.state.emailError && "has-error")}
                    value={this.state.value}
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                  />

                  {this.state.emailError && (
                    <p className="error">{this.state.emailError}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>

                    {this.state.items.length !== 0 ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={loading}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled>Save</Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      </span>
    );
  }
}

export default AddAzureAlert;
