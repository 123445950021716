import React, { Component } from "react";
import { Row, Col } from "antd";
import cloudLimit from "../../../assests/img/cloud-limit.png";
import alert from "../../../assests/img/alert.png";
import AzureBudgetAlert from "./AzureBudgetAlert";
import AwsBudgetAlert from "./AwsBudgetAlert";
import GcpBudgetAlert from "./GcpBudgetAlert";

export default class BudgetAlert extends Component {
  render() {
    return (
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="d-flex flex-row">
              <div className="pages-header">Budget Alert</div>
              <img
                style={{ margin: "6.5px 0 0 5px" }}
                src={alert}
                width={25}
                height={25}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <AddCloudLimit /> */}
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <AzureBudgetAlert />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <AwsBudgetAlert />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <GcpBudgetAlert />
          </Col>
        </Row>
      </div>
    );
  }
}
