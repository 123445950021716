import React, { useState, useEffect, useRef } from "react";
import { Button, Tooltip, Row, Col, Empty, Select, Form, Spin } from "antd";
import { Pie, G2, Column } from "@ant-design/plots";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import axios from "axios";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";
var initialProjectName = "";
const { Option } = Select;
var initialCurrency = "INR";
const G = G2.getEngine("canvas");

export const GetProjectWiseDetails = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");

  // useEffect(() => {
  //   setLoading(true);
  //   setData([]);
  // }, [Currency]);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      setData([]);
      setprojectName("");
      let initialProject = [];
      fetch(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectlist`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((json) => {
          initialProject = json;
          initialProjectName = initialProject[0].project_name;
          setprojectName(initialProjectName);
          setProjects(json);
          setCurrency(Currency);
          asyncFetch(initialProjectName, Currency, accessToken);
        })
        .catch((error) => {
          console.log("fetch data failed", error);
        });
    })();
  }, [Currency]);

  const asyncFetch = async (project, Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectscost?projectname=${project}&currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) {
          filterData.cost = parseInt(filterData.cost);
          return filterData;
        }
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };

  const config = {
    appendPadding: 10,
    data,
    angleField: "cost",
    colorField: "month",
    radius: 0.8,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 10,
            text: `${data.cost}`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 400,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
      {
        type: "element-selected",
      },
    ],
  };

  const barConfig = {
    data,
    xField: "month",
    yField: "cost",

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    scrollbar: {
      type: "horizontal",
    },
    minColumnWidth: 20,
    maxColumnWidth: 20,
  };

  const handleProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    setprojectName(value);
    asyncFetch(value, currency, accessToken);
  };

  let projectOptions = projects.map((project) => {
    return (
      <Option name={project.project_name} value={project.project_name}>
        {project.project_name}
      </Option>
    );
  });

  const headers = [
    { label: "Name", key: "project_name" },
    { label: "Cost", key: "cost" },
    { label: "Month", key: "month" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Project Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">Monthly</h5>
            </div>
            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={19} sm={19} md={19} lg={19} xl={19}>
                  <Select
                    defaultValue={project}
                    style={{ width: "50%" }}
                    showSearch
                    placeholder="Select Project"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleProjectChange}
                  >
                    {projectOptions}
                  </Select>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.GCP_TYPE + "Project Billing_Monthly"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>

                  <Tooltip title="Export to PDF">
                    <div className="download-btn float-end aws-sb-btn3">
                      <Button size="large" type="text" onClick={printDocument}>
                        <FilePdfOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {loading && (
                  <div className="mt-5 d-grid">
                    <Spin indicator={antIcon} />
                    <p className="d-flex justify-content-center text-secondary">
                      Loading...
                    </p>
                  </div>
                )}
                {(() => {
                  if (data.length === 0 && !loading) {
                    return (
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                          height: 100,
                        }}
                      />
                    );
                  } else {
                    return (
                      <>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Pie {...config} />;
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Column {...barConfig} />
                          </Col>
                        </Row>
                      </>
                    );
                  }
                })()}
              </Col>
              {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {(() => {
                  if (data.length !== 0) {
                    return <Column {...barConfig} />;
                  }
                })()}
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};
