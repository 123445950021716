// ***************************** Billing ****************************//

const awsConfig = {
  Auth: {
    identityPoolId: "ap-south-1:716a25d4-050f-4455-8c63-29a28ff20b8f",
    region: "ap-south-1",
    userPoolId: "ap-south-1_JGI5OcEzk",
    userPoolWebClientId: "7gap6g53racnnnmcf4jb2scu2q",
  },
  oauth: {
    domain: "prod-cloud-billing.auth.ap-south-1.amazoncognito.com",
    scope: ["email", "openid"],
    redirectSignIn: window.origin,
    redirectSignOut: window.origin,
    responseType: "code",
  },
};

export default awsConfig;
