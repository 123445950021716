import React, { useState, useEffect, useRef } from "react";
import { Button, Tooltip, Row, Col, Empty, Select, Spin } from "antd";
import { Pie, G2 } from "@ant-design/plots";
import { Column } from "@ant-design/plots";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import axios from "axios";
import moment from "moment";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
var initialProjectName = "";
const { Option } = Select;
var initialCurrency = "INR";
const G = G2.getEngine("canvas");

export const GetCostCreditsBar = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("INR");
  const [month, setMonth] = useState(initalMonth);

  useEffect(() => {
    setLoading(true);
    setData([]);
  }, [Currency]);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setprojectName("");
      let initialProject = [];
      fetch(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/projectlist`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((json) => {
          initialProject = json;
          initialProjectName = initialProject[0].project_name;
          setprojectName(initialProjectName);
          setProjects(json);
          setCurrency(Currency);
          asyncFetch(month, initialProjectName, Currency, accessToken);
        })
        .catch((error) => {
          console.log("fetch data failed", error);
        });
    })();
  }, [Currency]);

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const asyncFetch = async (month, project, a, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/creditscost?projectname=${project}&currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.value > 0) {
          filterData.value = parseInt(filterData.value);
          return filterData;
        }
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const handleMonthChange = async (data, dateString) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setMonth(dateString);
    asyncFetch(dateString, project, currency, accessToken);
  };
  const config = {
    data,
    isStack: true,
    xField: "month",
    yField: "value",
    seriesField: "type",
    minColumnWidth: 30,
    maxColumnWidth: 30,
    scrollbar: {
      type: "horizontal",
    },
  };

  const handleProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();

    setLoading(true);
    setData([]);
    setprojectName(value);
    asyncFetch(month, value, currency, accessToken);
  };

  let projectOptions = projects.map((project) => {
    return (
      <Option name={project.project_name} value={project.project_name}>
        {project.project_name}
      </Option>
    );
  });

  const headers = [
    { label: "Month", key: "month" },
    { label: "Currency", key: "currency" },
    { label: "Type", key: "type" },
    { label: "Cost", key: "value" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Cost & Credits</h3>
              <h5 className="text-secondary  mt-2 ms-3">
                Month & Project wise
              </h5>
            </div>
            {project && (
              <Row gutter={[8, 8]} className="my-3">
                <Col xs={15} sm={15} md={15} lg={15} xl={15}>
                  <Select
                    defaultValue={project}
                    style={{ width: "50%" }}
                    showSearch
                    placeholder="Select Project"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleProjectChange}
                  >
                    {projectOptions}
                  </Select>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.GCP_TYPE + "Project Billing_Monthly"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>

                  <Tooltip title="Export to PDF">
                    <div className="download-btn float-end aws-sb-btn3">
                      <Button size="large" type="text" onClick={printDocument}>
                        <FilePdfOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
            )}

            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else {
                return <Column {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
