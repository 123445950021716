import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Space,
  Table,
  Input,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Tag,
  Form,
  Modal,
  message,
  Select,
  Checkbox,
  notification,
} from "antd";

import {
  SearchOutlined,
  EyeOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  ReloadOutlined,
  BellOutlined,
  FilterOutlined,
  EditOutlined,
} from "@ant-design/icons/lib/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import axios from "axios";
import AddTagFormOne from "./AddTagFormOne";
import SelectComponent from "./SelectComponent";
import { Auth } from "aws-amplify";
import AddAlertAzure from "./AddAlertAzure";

const { Option } = Select;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
let checkStartOfMonth = moment().format("D");
var initalMonth;
if (checkStartOfMonth == 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const monthFormat = "YYYY-MM";
let initialAccountId;
var initialProjectName = "";

export const TagDetailsList = (props) => {
  const [month, setMonth] = useState(initalMonth);
  const [visible, setVisible] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [awsServices, setAWSServices] = useState([]);
  const [gcpServices, setGCPServices] = useState([]);
  const [azureServices, setAzureServices] = useState([]);
  const [cloudType, setCloudType] = useState("Azure");
  const [selected, setSelected] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [viewTags, setViewTags] = useState([]);
  const [viewTagsAzure, setViewTagsAzure] = useState([]);
  const [viewEditTags, setEditViewTags] = useState([]);
  const [tagsSelect, setTagsSelect] = useState([]);
  const [tagAdded, setTagAdded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [disableValue, setDisableValue] = useState(true);
  const [awsArn, setAwsArn] = useState("");
  const [viewTagsGCP, setViewTagsGCP] = useState([]);
  const [project, setProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [reload, setReload] = useState(false);
  const [awsNotification, setAwsNotification] = useState(false);
  const [gcpNotification, setGcpNotification] = useState(false);
  const [azureNotification, setAzureNotification] = useState(false);
  const [awsRecord, setAwsRecord] = useState({});
  const [azureRecord, setAzureRecord] = useState({});
  const [gcpRecord, setGcpRecord] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [azureAccountId, setAzureAccountId] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [gcpTotalCost, setGcpTotalCost] = useState(0);
  const [totalCostFilter, setTotalCostFilter] = useState(0);
  const [azureViewloading, setAzureViewLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [awsModalOpen, setawsModalOpen] = useState(false);
  const [gcpModalOpen, setGcpModalOpen] = useState(false);

  const [azureKey, setAzureKey] = useState("");
  const [azureValue, setAzureValue] = useState("");
  const [awsKey, setAwsKey] = useState("");
  const [awsValue, setAwsValue] = useState("");
  const [gcpKey, setGcpKey] = useState("");
  const [gcpValue, setGcpValue] = useState("");
  const [tagloading, setTagLoading] = useState(false);
  const [resource_name, setresource_name] = useState("");
  const [resource_id, setresource_id] = useState("");
  const [service_id, setservice_id] = useState("");
  const [account_id, setaccount_id] = useState("");
  const [limit, setlimit] = useState(0);
  const [notification_emails, setnotification_emails] = useState("");
  const [email_alert, setemail_alert] = useState(false);
  const [whatsapp_alert, setwhatsapp_alert] = useState(false);
  const [ivrs_alert, setivrs_alert] = useState(false);
  const [awsAccountId, setAwsAccountId] = useState("");
  const [awsTotalCost, setAwsTotalCost] = useState(0);
  const [budgetName, setBudgetName] = useState("");
  const [azureAlertData, setAzureAlertData] = useState("");
  const [allAzureAlertData, setAllAzureAlertData] = useState("");

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      // if (cloudType === "AWS") {
      //   setLoading(true);
      //   getAWSData(month, awsAccountId, accessToken);
      // } else if (cloudType === "GCP") {
      //   setLoading(true);
      //   getGCPData(month, project, accessToken);
      // } else if (cloudType === "Azure") {
      //   setLoading(true);

      //   getAzureData(month, azureAccountId, accessToken);
      // }
    })();
  }, [cloudType]);

  useEffect(() => {
    let total = 0;
    setTotalCostFilter(0);
    azureServices.forEach((res) => {
      total += res.Cost;
    });
    setTotalCost(total.toFixed(2));
  }, [azureServices]);

  useEffect(() => {
    let total = 0;
    setTotalCostFilter(0);

    gcpServices.forEach((res) => {
      if (res.sum !== "0") total += res.sum;
    });
    setGcpTotalCost(total.toFixed(2));
  }, [gcpServices]);

  useEffect(() => {
    let total = 0;
    setTotalCostFilter(0);
    awsServices.forEach((res) => {
      let cost = Number(res.cost);
      total += cost;
    });
    setAwsTotalCost(total.toFixed(2));
  }, [awsServices]);

  const showmyModal = () => {
    setIsModalOpen(true);
  };
  const showAwsModal = () => {
    setawsModalOpen(true);
  };

  const showGcpModal = () => {
    setGcpModalOpen(true);
  };

  const handleGcpOk = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setTagLoading(true);
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/resourcelist?resourcename&projectname=${project}&month=${month}&tag_key=${gcpKey}&tag_value=${gcpValue}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    setGCPServices(response.data);
    setGcpKey("");
    setGcpValue("");
    setTagLoading(false);
    setGcpModalOpen(false);
  };

  const handleOk = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setTagLoading(true);
    let response = await axios.post(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/tagsfilter?monthYear=${month}&accountId=${azureAccountId}&tagKey=${azureKey}&tagValue=${azureValue}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    setAzureServices(response.data);
    setAzureKey("");
    setAzureValue("");
    setTagLoading(false);
    setIsModalOpen(false);
  };

  const handleAwsOk = async () => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setTagLoading(true);
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/resourcestagscost?month=${month}&account_id=${awsAccountId}&tag_key=${awsKey}&tag_value=${awsValue}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    setAWSServices(response.data);
    setAwsKey("");
    setAwsValue("");
    setTagLoading(false);
    setawsModalOpen(false);
  };

  const handleCancel = () => {
    setAzureKey("");
    setAzureValue("");
    setIsModalOpen(false);
    setTagLoading(false);
  };

  const handleAWSCancel = () => {
    setAwsKey("");
    setAwsValue("");
    setawsModalOpen(false);
    setTagLoading(false);
  };

  const handleGCPCancel = () => {
    setGcpKey("");
    setGcpValue("");
    setGcpModalOpen(false);
    setTagLoading(false);
  };

  const getAWSData = async (month, accountId, accessToken) => {
    setLoading(true);
    setAwsAccountId(accountId);
    await axios
      .get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/resourcestagscost?month=${month}&account_id=${accountId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("AWS Response=========>", response.data);
        setLoading(false);
        setAWSServices(response.data);
        setGCPServices([]);
      })
      .catch((error) => {
        console.log("Error=========>", error);
      });
  };

  const onTagAdd = async (month) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    // getAWSData(accessToken);
    getAzureData(month, azureAccountId, accessToken);
    // getGCPData(month, project, accessToken);
    setTagAdded(true);
  };

  const getGCPData = async (month, projectnameis, accessToken) => {
    setLoading(true);
    setProject(projectnameis);
    await axios
      .get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/resourcelist?resourcename&projectname=${projectnameis}&month=${month}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("GCP Response", response.data);
        setGCPServices(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getAzureData = async (month, accountId, accessToken) => {
    setLoading(true);
    setAzureAccountId(accountId);
    await axios
      .get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/resourcewise?monthYear=${month}&accountId=${accountId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("Azure Response>", response.data);
        setAzureServices(response.data);
        setGCPServices([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error>", error);
      });
    let newresponse = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets?accountId=${accountId}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("allazuredata", newresponse.data.value);
    setAllAzureAlertData(newresponse.data.value);
  };

  const getViewTagData = async (servicename, tags) => {
    // setAzureViewLoading(true);
    // let accessToken = (await Auth.currentSession())
    //   .getAccessToken()
    //   .getJwtToken();
    // await axios
    //   .get(
    //     `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/singleresource?resourceId=${servicename}`,
    //     { headers: { Authorization: "Bearer " + accessToken } }
    //   )
    //   .then((response) => {
    //     console.log("view tag Response aws>", response);
    //     if (response.data.data.length !== 0) {
    //       console.log("inside of when data is empty");
    //       const filteredData = response.data.data[0].Tags.filter(
    //         (filterData) => {
    //           if (filterData.Key != null && filterData.Value != null)
    //             return filterData;
    //         }
    //       );
    //       setViewTags(filteredData);
    //       setEditViewTags(filteredData);
    //       setAzureViewLoading(false);
    //     }
    //     setAzureViewLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log("Error>", error);
    //   });
    setAzureViewLoading(true);
    console.log("tags inside get view tag data", tags);
    setViewTags(tags);
    setAzureViewLoading(false);
  };

  const getViewTagDataAzure = async (resourceId, systemData) => {
    setAzureViewLoading(true);
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await axios
      .get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/taglist?resourceId=${resourceId}&accountId=${azureAccountId}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("azure view tag Response>", response.data.Tags);
        const filteredData = response.data.Tags.filter((filterData) => {
          if (filterData.Key != null && filterData.Value != null)
            return filterData;
        });
        if (systemData == undefined) {
          setViewTagsAzure(filteredData);
          setAzureViewLoading(false);
        } else {
          for (let index = 0; index < filteredData.length; index++) {
            const element = filteredData[index];
            element["createdByType"] = systemData.createdByType;
          }
          setViewTagsAzure(filteredData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error>", error);
      });
  };

  const getViewTagDataGCP = async (resourcename) => {
    setAzureViewLoading(true);
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await axios
      .get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/gcp/resourcelist?resourcename=${resourcename}&month=2023-04`,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("gcp view tag Response>", response);
        const filteredData = response.data.filter((filterData) => {
          if (filterData.tagKey != null && filterData.tagValue != null)
            return filterData;
        });
        setViewTagsGCP(filteredData);
        setAzureViewLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const editTagData = async (tags) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await axios
      .put(
        `https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/tags`,
        tags,
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("edit tag Response>", response.data);
        getAWSData(accessToken);
        getAzureData("2023-01", accessToken);
        getGCPData(month, project, accessToken);
      })
      .catch((error) => {
        console.log("Error>", error);
      });
  };
  const chooseCloud = (cloudName) => {
    setHasSelected(false);
    setAWSServices([]);
    setGCPServices([]);
    setAzureServices([]);
    setCloudType(cloudName);
  };

  const selectTags = (tags) => {
    setTagsSelect(tags);
  };

  const selectMonth = (month) => {
    setMonth(month);
  };

  const deleteTagData = async (id) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let reqbody = {};
    reqbody.ResourceARNList = [awsArn];
    reqbody.TagKeys = [id];
    const headers = { Authorization: "Bearer " + accessToken };
    const data = reqbody;
    await axios
      .delete(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/tags`,
        { headers, data }
      )
      .then((response) => {
        console.log("delete resp", response);
        setLoading(false);
        if (response.data.statusCode == 200) {
          message.success("Tag deleted Successfully!");
          getViewTagData(awsArn);
          getAWSData(accessToken);
          setVisible(false);
        } else {
          message.error(response.data.body);
          setVisible(false);
        }
      })
      .catch((error) => {
        message.error("Tag cannot be deleted!");
        console.log("Error", error);
      });
  };

  const deleteTagDataAzure = async (resourceId) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    await axios
      .delete(
        `https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/azurerest/azureresourceslist`,
        { data: { queryStringParameters: { resourceId: resourceId } } },
        { headers: { Authorization: "Bearer " + accessToken } }
      )
      .then((response) => {
        console.log("delete resp", response);
        setLoading(false);
        if (response.data.statusCode == 200) {
          message.success("Tag deleted Successfully!");
          getViewTagData(awsArn);
          getAWSData(accessToken);
          getGCPData(month, project, accessToken);
          getAzureData(accessToken);
          setVisible(false);
        }
      })
      .catch((error) => {
        message.error("Tag cannot be deleted!");
        console.log("Error>", error);
      });
  };

  const handleModalCancel = () => {
    setVisible(false);
    setEditViewTags([]);
  };
  const handleViewTagCancel = () => {
    setViewMode(false);
    setViewTags([]);
    setViewTagsAzure([]);
    setViewTagsGCP([]);
  };
  const onFinish = async (values) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let temp = values["tags"];
    editTagData(temp);
    getAWSData(accessToken);
    getGCPData(month, project, accessToken);
    getAzureData("2023-01", accessToken);
    setVisible(false);
  };
  const onSelectChange = (newSelectedRowKeys, selectedRow) => {
    setSelectedServices(selectedRow);
    setSelectedRowKeys(newSelectedRowKeys);
    if (newSelectedRowKeys.length > 0) {
      props.rowSelected(true);
    } else {
      props.rowSelected(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setHasSelected(true);
      onSelectChange(selectedRowKeys, selectedRows);
    },
  };

  const [hasSelected, setHasSelected] = useState(false);

  const showModal = (servicename) => {
    setVisible(true);
    getViewTagData(servicename);
    setDisableValue(true);
  };

  const showViewTagModal = (servicename, record) => {
    console.log("record inside show view tag modal===", record);
    setAwsArn(servicename);
    setViewMode(true);
    getViewTagData(servicename, record.tags);
  };

  const showViewTagModalAzure = (id, record) => {
    setViewMode(true);
    getViewTagDataAzure(id, record.systemData);
  };

  const showViewTagModalGCP = (resourcename, record) => {
    console.log("resource name inside gcp view tag", record);
    setViewMode(true);
    getViewTagDataGCP(resourcename);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    let totalCost = 0;

    if (azureServices.length > 0) {
      azureServices.map((item) => {
        if (item.ResourceName.includes(selectedKeys)) {
          totalCost += item.Cost;
        }
      });
    }
    if (gcpServices.length > 0) {
      console.log("gcpServices=====>", gcpServices);
      gcpServices.map((item) => {
        if (item.name.includes(selectedKeys)) {
          totalCost += item.sum;
        }
      });
    }
    if (awsServices.length > 0) {
      awsServices.map((item) => {
        if (item.resource_id.includes(selectedKeys)) {
          let cost = Number(item.cost);
          totalCost += cost;
        }
      });
    }
    setTotalCostFilter(totalCost.toFixed(2));
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setTotalCostFilter(0);
    setSearchText("");
  };

  const reloadPage = async () => {
    setReload(true);
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    if (cloudType === "AWS") {
      console.log("AWS Reload data", month, awsAccountId);
      getAWSData(month, awsAccountId, accessToken);
    } else if (cloudType === "GCP") {
      console.log("GCP Reload data", month, project);

      getGCPData(month, project, accessToken);
    } else if (cloudType === "Azure") {
      console.log("Azure Reload data", month, azureAccountId);

      getAzureData(month, azureAccountId, accessToken);
    }
    setReload(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const showAwsNotificationModal = (record) => {
    setAwsRecord(record);
    setresource_name("");
    setresource_id(record.ResourceARN);
    setaccount_id("");
    setservice_id(record.resourcetype);
    setAwsNotification(true);
  };
  const awsNotificationModalCancel = () => {
    setAwsNotification(false);
  };
  const showGcpNotificationModal = (record) => {
    setGcpRecord(record);
    setresource_name("");
    setresource_id("");
    setaccount_id("");
    setservice_id("");
    setGcpNotification(true);
  };

  const gcpNotificationModalCancel = () => {
    setGcpNotification(false);
  };

  const azureNotificationModal = async (record) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    console.log("record", record);

    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets?accountId=${record.AccountId}&resourceGroupName=${record.ResourceGroupName}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("hieee", response.data.value);
    setAzureAlertData(response.data.value);
    setAllAzureAlertData(response.data.value);
    setAzureNotification(true);
  };

  const azureAlertColumns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Time Grain",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.timeGrain;
      },
    },
    {
      title: "Max Amount",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.amount;
      },
    },
    {
      title: "Current Usage",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.currentSpend.amount.toFixed(2);
      },
    },
    {
      title: "Currency",
      key: "",
      dataIndex: "",
      render: (record) => {
        return record.properties.currentSpend.unit;
      },
    },

    {
      title: "Threshold",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return record.properties.notifications.actual_GreaterThan_Percent
          ?.threshold;
      },
    },
    {
      title: "Emails",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => (
        <>
          {record.properties.notifications.actual_GreaterThan_Percent?.contactEmails?.map(
            (email) => {
              return <div>{email}</div>;
            }
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Row>
          <Col>
            <Popconfirm
              title="Are you sure to delete this Budget?"
              // onConfirm={() => {
              //   deleteTagData(record.Key);
              // }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Alert">
                <Button type="link" size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
          <Col>
            <Tooltip title="Edit Alert">
              <Button type="link" size="small">
                <EditOutlined style={{ color: "#5046e5" }} />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  const azureNotificationModalCancel = () => {
    setAzureNotification(false);
  };

  const handleAWSSubmit = () => {
    axios
      .post(
        "https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/billingnotificationbyresource",
        {
          cloud_name: "AWS",
          resource_name: resource_name,
          resource_id: resource_id,
          service_id: service_id,
          account_id: account_id,
          limit: limit,
          notification_emails: notification_emails,
          email_alert: email_alert,
          whatsapp_alert: whatsapp_alert,
          ivrs_alert: ivrs_alert,
        }
      )
      .then((response) => {
        console.log("create notif Response", response);
        if (response.status == 200) {
          notification.open({
            message: "AWS Billing Notification Created!!",
            description: "Now, you can rest easy as we will notify you",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        } else {
          notification.open({
            message: "AWS Billing Notification Creation Failed!!",
            description: "Oooooops!",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
        setAwsNotification(false);
      });
  };
  const handleGCPSubmit = () => {
    axios
      .post(
        "https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/billingnotificationbyresource",
        {
          cloud_name: "GCP",
          resource_name: resource_name,
          resource_id: resource_id,
          service_id: service_id,
          account_id: account_id,
          limit: limit,
          notification_emails: notification_emails,
          email_alert: email_alert,
          whatsapp_alert: whatsapp_alert,
          ivrs_alert: ivrs_alert,
        }
      )
      .then((response) => {
        console.log("create notif Response", response);
        if (response.status == 200) {
          notification.open({
            message: "GCP Billing Notification Created!!",
            description: "Now, you can rest easy as we will notify you",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        } else {
          notification.open({
            message: "GCP Billing Notification Creation Failed!!",
            description: "Oooooops!",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
        setGcpNotification(false);
      });
  };
  const handleAzureSubmit = () => {
    axios
      .post(
        "https://8m8xf34hl0.execute-api.ap-south-1.amazonaws.com/dev/billingnotificationbyresource",
        {
          cloud_name: "Azure",
          resource_name: resource_name,
          resource_id: resource_id,
          service_id: service_id,
          account_id: account_id,
          limit: limit,
          notification_emails: notification_emails,
          email_alert: email_alert,
          whatsapp_alert: whatsapp_alert,
          ivrs_alert: ivrs_alert,
        }
      )
      .then((response) => {
        console.log("create notif Response", response);
        if (response.status == 200) {
          notification.open({
            message: "Azure Billing Notification Created!!",
            description: "Now, you can rest easy as we will notify you",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        } else {
          notification.open({
            message: "Azure Billing Notification Creation Failed!!",
            description: "Oooooops!",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
        setAzureNotification(false);
      });
  };

  const awscolumns = [
    {
      title: "Resource Name",
      dataIndex: "",
      key: "",
      width: "30%",
      ...getColumnSearchProps("resource_id"),
      render: (record) => {
        if (record.resource_name === "NA") {
          return record.resource_id;
        } else {
          return record.resource_name;
        }
      },
    },
    {
      title: "Resource Type",
      dataIndex: "",
      key: "",
      render: (record) => {
        if (record.service_name === "NA") {
          return <></>;
        } else {
          return record.service_name;
        }
      },
    },
    {
      title: () => {
        return (
          <div>
            Tags
            <Button
              onClick={showAwsModal}
              className="float-end"
              style={{ border: "none" }}
            >
              <FilterOutlined />
            </Button>
            <Modal
              title="Filter for Tag Key and Value"
              open={awsModalOpen}
              onCancel={handleAWSCancel}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <div>
                <Form onFinish={handleAwsOk}>
                  <Row>
                    <Col xl={12}>
                      <Form.Item
                        name="key"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Key!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Key"
                          onChange={(event) => {
                            setAwsKey(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        name="value"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Value!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Value"
                          onChange={(event) => {
                            setAwsValue(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br></br>
                  <Row className="float-end">
                    <Form.Item>
                      <Button
                        className="close-modal me-3"
                        onClick={handleAWSCancel}
                      >
                        Close
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="ok-modal"
                        loading={tagloading}
                      >
                        Filter
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        );
      },
      key: "Key",
      dataIndex: "Key",
      width: "20%",
      render: (_, { tags }) => (
        <>
          {tags &&
            tags.map((tag) => {
              let color = tag.length > 5 ? "green" : "green";
              return (
                <Tag color={color} key={tag}>
                  {tag.Key}
                </Tag>
              );
            })}
        </>
      ),
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: "10%",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "Actions",
      key: "action",
      width: "15%",
      render: (record) => (
        <Row gutter={[0, 8]}>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            {record.tags.length == 0 ? (
              <Tooltip title="No Tags">
                <Button type="link" size="small">
                  <EyeInvisibleOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="View Tags">
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    showViewTagModal(record.resource_id, record);
                  }}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>
            )}
          </Col>
          {/* <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Button
              type="link"
              size="small"
              onClick={() => {
                showAwsNotificationModal(record);
              }}
            >
              <BellOutlined style={{ color: "green" }} />
            </Button>
          </Col> */}
        </Row>
      ),
    },
  ];

  const gcpcolumns = [
    {
      title: "Resource Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      width: "35%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Resource Type",
      dataIndex: "service_name",
      key: "service_name",
      width: "15%",
    },
    {
      title: () => {
        return (
          <div>
            Tags
            <Button
              onClick={showGcpModal}
              className="float-end"
              style={{ border: "none" }}
            >
              <FilterOutlined />
            </Button>
            <Modal
              title="Filter for Tag Key and Value"
              open={gcpModalOpen}
              onCancel={handleGCPCancel}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <div>
                <Form onFinish={handleGcpOk}>
                  <Row>
                    <Col xl={12}>
                      <Form.Item
                        name="key"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Key!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Key"
                          onChange={(event) => {
                            setGcpKey(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        name="value"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Value!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Value"
                          onChange={(event) => {
                            setGcpValue(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br></br>
                  <Row className="float-end">
                    <Form.Item>
                      <Button
                        className="close-modal me-3"
                        onClick={handleGCPCancel}
                      >
                        Close
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="ok-modal"
                        loading={tagloading}
                      >
                        Filter
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        );
      },
      dataIndex: "tagKey",
      key: "tagKey",
      width: "25%",
      render: (_, { tags }) => (
        <>
          <div style={{ maxHeight: "150px", overflow: "auto" }}>
            {tags &&
              tags.map((tag) => {
                let color = tag.length > 5 ? "green" : "green";
                return (
                  <Tag color={color} key={tag}>
                    {tag.tagKey}
                  </Tag>
                );
              })}
          </div>
        </>
      ),
    },
    {
      title: "Cost",
      dataIndex: "",
      key: "",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title={record.sum} key={record.sum}>
            ${record.sum}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      width: "15%",
    },
    {
      title: "Actions",
      key: "action",
      width: "20%",
      render: (record) => (
        <Row gutter={[0, 8]}>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            {/* <Tooltip title="View Tags">
              <Button
                type="link"
                size="small"
                onClick={() => {
                  showViewTagModalGCP(record.global_name, record);
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip> */}
            {record.tags.length === 0 ? (
              <Tooltip title="No Tags">
                <Button type="link" size="small">
                  <EyeInvisibleOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  showViewTagModalGCP(record.ResourceId, record);
                }}
              >
                <Tooltip title="View Tags">
                  <EyeOutlined />
                </Tooltip>
              </Button>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const azurecolumns = [
    {
      title: "Resource Name",
      dataIndex: "ResourceName",
      key: "ResourceName ",
      width: "25%",
      sortDirections: ["ascend"],
      sorter: (a, b) => a.ResourceName - b.ResourceName,
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("ResourceName"),
    },
    {
      title: "Resource Type",
      dataIndex: "ServiceName",
      key: "ServiceName",
      width: "20%",
    },
    {
      title: () => {
        return (
          <div>
            Tags
            <Button
              onClick={showmyModal}
              className="float-end"
              style={{ border: "none" }}
            >
              <FilterOutlined />
            </Button>
            <Modal
              title="Filter for Tag Key and Value"
              open={isModalOpen}
              // onOk={handleOk}
              onCancel={handleCancel}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <div>
                <Form onFinish={handleOk}>
                  <Row>
                    <Col xl={12}>
                      <Form.Item
                        name="key"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Key!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Key"
                          onChange={(event) => {
                            setAzureKey(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        name="value"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Tag Value!",
                          },
                        ]}
                      >
                        <Input
                          allowClear={true}
                          style={{ width: "200px" }}
                          placeholder="Tag Value"
                          onChange={(event) => {
                            setAzureValue(event.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br></br>
                  <Row className="float-end">
                    <Form.Item>
                      <Button
                        className="close-modal me-3"
                        onClick={handleCancel}
                      >
                        Close
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="ok-modal"
                        loading={tagloading}
                      >
                        Filter
                      </Button>
                    </Form.Item>
                  </Row>
                </Form>
              </div>
            </Modal>
          </div>
        );
      },
      key: "Key",
      dataIndex: "Key",
      width: "20%",
      render: (_, { Tags }) => (
        <>
          {Tags &&
            Tags.map((tag) => {
              let color = tag.length > 5 ? "green" : "green";
              return (
                <Tag color={color} key={tag}>
                  {tag.Key}
                </Tag>
              );
            })}
        </>
      ),
    },

    {
      title: "Region",
      dataIndex: "ResourceLocation",
      key: "ResourceLocation",
      width: "15%",
    },

    {
      title: "Cost",
      dataIndex: "",
      width: "15%",
      render: (record) => (
        <>
          <Tooltip title={record.Cost} key={record.Cost}>
            ₹{record.Cost.toFixed(2)}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      render: (record) => (
        <Row gutter={[0, 8]}>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            {record.Tags.length === 0 ? (
              <Tooltip title="No Tags">
                <Button type="link" size="small">
                  <EyeInvisibleOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  showViewTagModalAzure(record.ResourceId, record);
                }}
              >
                <Tooltip title="View Tags">
                  <EyeOutlined />
                </Tooltip>
              </Button>
            )}
          </Col>
          {/* <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            {record.Tags == undefined ? (
              <Tooltip title="No Tags">
                <Button type="link" size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Tooltip>
            ) : (
              <Popconfirm
                title="Are you sure to delete this Tag?"
                onConfirm={() => {
                  deleteTagDataAzure(record.ResourceId);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Tag">
                  <Button type="link" size="small">
                    <DeleteOutlined style={{ color: "red" }} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
          </Col> */}
          {/* <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Button
              type="link"
              size="small"
              onClick={() => {
                azureNotificationModal(record);
              }}
            >
              <BellOutlined style={{ color: "green" }} />
            </Button>
          </Col> */}
        </Row>
      ),
    },
  ];

  const viewTagsColumns = [
    {
      title: "Tag Key",
      dataIndex: "Key",
      key: "Key",
      width: "40%",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Tag Value",
      dataIndex: "Value",
      key: "Value",
      width: "40%",

      render: (text) => <a>{text}</a>,
    },

    // {
    //   title: "Actions",
    //   key: "action",
    //   width: "30%",

    //   render: (record) => (
    //     <Popconfirm
    //       title="Are you sure to delete this Tag?"
    //       onConfirm={() => {
    //         deleteTagData(record.Key);
    //       }}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Tooltip title="Delete Tag">
    //         <Button type="link" size="small">
    //           <DeleteOutlined style={{ color: "red" }} />
    //         </Button>
    //       </Tooltip>
    //     </Popconfirm>
    //   ),
    // },
  ];

  const viewTagsColumnsAzure = [
    {
      title: "Tag Key",
      dataIndex: "Key",
      key: "Key",
      width: "40%",

      render: (text) => <a>{text}</a>,
    },

    {
      title: "Tag Value",
      dataIndex: "Value",
      key: "Value",
      width: "40%",
      render: (text) => (
        <>
          <div
            style={{
              maxWidth: "200px",
              minWidth: "0px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {text}
          </div>
        </>
      ),
    },

    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (record) => (
    //     <Popconfirm
    //       title="Are you sure to delete this Tag?"
    //       onConfirm={() => {
    //         deleteTagDataAzure(record.id);
    //       }}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Tooltip title="Delete Tag">
    //         <Button type="link" size="small">
    //           <DeleteOutlined style={{ color: "red" }} />
    //         </Button>
    //       </Tooltip>
    //     </Popconfirm>
    //   ),
    // },
  ];

  const viewTagsColumnsGCP = [
    {
      title: "Tag Key",
      dataIndex: "tagKey",
      key: "tagKey",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Tag Value",
      dataIndex: "tagValue",
      key: "tagValue",
      render: (text) => <a>{text}</a>,
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (record) => (
    //     <Popconfirm
    //       title="Are you sure to delete this Tag?"
    //       onConfirm={() => {
    //         // deleteTagData(record.Key);
    //       }}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Tooltip title="Delete Tag">
    //         <Button type="link" size="small">
    //           <DeleteOutlined style={{ color: "red" }} />
    //         </Button>
    //       </Tooltip>
    //     </Popconfirm>
    //   ),
    // },
  ];

  return (
    <div className="my-4 cloud-charts">
      <Row>
        <Col xs={19} sm={19} md={19} lg={19} xl={19}>
          <SelectComponent
            chooseCloud={chooseCloud}
            selectMonth={selectMonth}
            selectTags={selectTags}
            awsData={awsServices}
            gcpData={gcpServices}
            azureData={azureServices}
            setAWSServices={setAWSServices}
            setGCPServices={setGCPServices}
            setAzureServices={setAzureServices}
            getGCPData={getGCPData}
            getAWSData={getAWSData}
            getAzureData={getAzureData}
          />
        </Col>

        {/* {cloudType === "Azure" ? (
          <>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <AddTagFormOne
                selected={selected}
                selectedServices={selectedServices}
                disabled={hasSelected}
                tagAdded={onTagAdd}
                cloudType={cloudType}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <AddAlertAzure
                selected={selected}
                selectedServices={selectedServices}
                disabled={hasSelected}
                cloudType={cloudType}
                allAzureAlertData={allAzureAlertData}
              />
            </Col>
          </>
        ) : ( */}

        {cloudType === "Azure" ? (
          <>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <AddTagFormOne
                selected={selected}
                selectedServices={selectedServices}
                disabled={hasSelected}
                tagAdded={onTagAdd}
                cloudType={cloudType}
              />
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
      <div className="card mt-5">
        <div className="card-body p-5">
          <Row>
            {/* {cloudType === "AWS" ? (
              <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                <div className="d-flex flex-row my-2">
                  <h2 className="">Tags Detail</h2>
                </div>
              </Col>
            ) : ( */}
            <Col xs={15} sm={15} md={12} lg={19} xl={19}>
              <div className="d-flex flex-row my-2">
                <h2 className="">Tags Detail</h2>
              </div>
            </Col>
            {/* )} */}

            {cloudType === "GCP" ? (
              <Col xs={7} sm={7} md={10} lg={3} xl={3}>
                <div className="d-flex flex-row my-3 ">
                  {totalCostFilter !== 0 ? (
                    <p
                      style={{
                        fontSize: " initial",
                        fontStyle: "oblique",
                        fontWeight: "bold",
                      }}
                    >{`Total Cost  $${totalCostFilter}`}</p>
                  ) : (
                    <p
                      style={{
                        fontSize: " initial",
                        fontStyle: "oblique",
                        fontWeight: "bold",
                      }}
                    >{`Total Cost  $${gcpTotalCost}`}</p>
                  )}
                </div>
              </Col>
            ) : cloudType === "AWS" ? (
              <>
                <Col xs={7} sm={7} md={10} lg={3} xl={3}>
                  <div className="d-flex flex-row my-3 ">
                    {totalCostFilter !== 0 ? (
                      <p
                        style={{
                          fontSize: " initial",
                          fontStyle: "oblique",
                          fontWeight: "bold",
                        }}
                      >{`Total Cost  $${totalCostFilter}`}</p>
                    ) : (
                      <p
                        style={{
                          fontSize: " initial",
                          fontStyle: "oblique",
                          fontWeight: "bold",
                        }}
                      >{`Total Cost  $${awsTotalCost}`}</p>
                    )}
                  </div>
                </Col>
              </>
            ) : (
              <Col xs={7} sm={7} md={10} lg={3} xl={3}>
                <div className="d-flex flex-row my-2 ">
                  {totalCostFilter !== 0 ? (
                    <p
                      style={{
                        fontSize: " initial",
                        fontStyle: "oblique",
                        fontWeight: "bold",
                      }}
                    >{`Total Cost  ₹${totalCostFilter}`}</p>
                  ) : (
                    <p
                      style={{
                        fontSize: " initial",
                        fontStyle: "oblique",
                        fontWeight: "bold",
                      }}
                    >{`Total Cost  ₹${totalCost}`}</p>
                  )}
                </div>
              </Col>
            )}

            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <Button
                className="float-end refreshButton"
                onClick={() => reloadPage()}
              >
                <ReloadOutlined spin={reload} />
              </Button>
            </Col>
          </Row>
          {cloudType === "AWS" ? (
            <Table
              columns={awscolumns}
              loading={loading}
              dataSource={awsServices}
              rowSelection={{ type: "radio", ...rowSelection }}
              rowKey={"resource_id"}
              scroll={{ y: 300 }}
              pagination={{ pageSize: 20 }}
            />
          ) : cloudType === "Azure" ? (
            <div>
              <Table
                columns={azurecolumns}
                loading={loading}
                dataSource={azureServices}
                rowSelection={{ type: "radio", ...rowSelection }}
                rowKey={"id"}
                scroll={{ y: 300 }}
                pagination={{ pageSize: 20 }}
              />
            </div>
          ) : cloudType === "GCP" ? (
            <Table
              columns={gcpcolumns}
              loading={loading}
              dataSource={gcpServices}
              rowSelection={{ type: "radio", ...rowSelection }}
              rowKey={"key"}
              scroll={{ y: 300 }}
              pagination={{ pageSize: 20 }}
            />
          ) : (
            <Table
              columns={azurecolumns}
              loading={loading}
              dataSource={azureServices}
              rowSelection={{ type: "radio", ...rowSelection }}
              rowKey={"id"}
              scroll={{ y: 300 }}
              pagination={{ pageSize: 20 }}
            />
          )}

          <Modal
            width={500}
            open={visible}
            onCancel={handleModalCancel}
            title="Edit Tag"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              style={{
                maxWidth: 550,
              }}
              autoComplete="off"
            >
              <Form.List name="tags">
                {(fields) => (
                  <>
                    {viewEditTags.map((data, i) => (
                      <Space
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          initialValue={data.tagId}
                          name={[i, "id"]}
                        ></Form.Item>
                        <Col>
                          <Form.Item
                            initialValue={data.tagKey}
                            name={[i, "tagKey"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Tag Key",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Key" />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            initialValue={data.tagValue}
                            name={[i, "tagValue"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing Tag value",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Value" />
                          </Form.Item>
                        </Col>
                        <Popconfirm
                          title="Are you sure to delete this Tag?"
                          onConfirm={() => {
                            deleteTagData(data.tagId);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link" size="small">
                            <Tooltip title="Delete Tag">
                              <DeleteOutlined style={{ color: "red" }} />{" "}
                            </Tooltip>
                          </Button>
                        </Popconfirm>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="ok-modal">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {viewMode && (
            <Modal
              width={700}
              open={viewMode}
              onCancel={handleViewTagCancel}
              title="View Tags"
              style={{ top: 75 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Table
                dataSource={
                  cloudType === "AWS"
                    ? viewTags
                    : cloudType === "GCP"
                    ? viewTagsGCP
                    : cloudType === "Azure"
                    ? viewTagsAzure
                    : null
                }
                loading={azureViewloading}
                pagination={false}
                columns={
                  cloudType === "AWS"
                    ? viewTagsColumns
                    : cloudType === "GCP"
                    ? viewTagsColumnsGCP
                    : cloudType === "Azure"
                    ? viewTagsColumnsAzure
                    : null
                }
              />
            </Modal>
          )}
          <Modal
            width={800}
            open={awsNotification}
            onCancel={awsNotificationModalCancel}
            title="Notification AWS"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form name="add-SBU" className="add-SBU">
              <Row gutter={[8, 0]}>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Cloud Type : AWS </h4>
                </Col>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Row>
                    <Col>
                      <h4>Resource Name : </h4>
                    </Col>
                    <Col>
                      <h4
                        style={{
                          maxWidth: "150px",
                          minWidth: "0px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip
                          title={awsRecord.ResourceARN}
                          key={awsRecord.ResourceARN}
                        >
                          {awsRecord.ResourceARN}
                        </Tooltip>
                      </h4>
                    </Col>
                  </Row>
                </Col>

                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Resource Type : {awsRecord.resourcetype} </h4>
                </Col>
              </Row>
              <br />
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="limit">
                    <Input
                      addonBefore="$"
                      placeholder="Enter Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        setlimit(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    name="notification_emails"
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onChange={(e) => {
                      setnotification_emails(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
              <Checkbox
                name="email_alert"
                onChange={(e) => {
                  setemail_alert(e.target.checked);
                }}
              >
                Email
              </Checkbox>
              <Checkbox
                name="whatsapp_alert"
                onChange={(e) => {
                  setwhatsapp_alert(e.target.checked);
                }}
              >
                Whatsapp
              </Checkbox>
              <Checkbox
                name="ivrs_alert"
                onChange={(e) => {
                  setivrs_alert(e.target.checked);
                }}
              >
                Call
              </Checkbox>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={awsNotificationModalCancel}
                    >
                      Close
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      onClick={handleAWSSubmit}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            width={800}
            open={gcpNotification}
            onCancel={gcpNotificationModalCancel}
            title="Notification GCP"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form name="add-SBU" className="add-SBU">
              <Row gutter={[8, 0]}>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Cloud Type : GCP</h4>
                </Col>
                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Row>
                    <Col>
                      <h4>Recource Name :</h4>
                    </Col>
                    <Col>
                      <h4
                        style={{
                          maxWidth: "150px",
                          minWidth: "0px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <Tooltip title={gcpRecord.name} key={gcpRecord.name}>
                          {gcpRecord.name}
                        </Tooltip>
                      </h4>
                    </Col>
                  </Row>
                </Col>

                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <h4>Resource Type : </h4>
                </Col>
              </Row>
              <br />
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item name="limit">
                    <Input
                      addonBefore="$"
                      placeholder="Enter Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        setlimit(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    name="notification_emails"
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onChange={(e) => {
                      setnotification_emails(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
              <Checkbox
                name="email_alert"
                onChange={(e) => {
                  setemail_alert(e.target.checked);
                }}
              >
                Email
              </Checkbox>
              <Checkbox
                name="whatsapp_alert"
                onChange={(e) => {
                  setwhatsapp_alert(e.target.checked);
                }}
              >
                Whatsapp
              </Checkbox>
              <Checkbox
                name="ivrs_alert"
                onChange={(e) => {
                  setivrs_alert(e.target.checked);
                }}
              >
                Call
              </Checkbox>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={gcpNotificationModalCancel}
                    >
                      Close
                    </Button>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      onClick={handleGCPSubmit}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            width={1000}
            open={azureNotification}
            onCancel={azureNotificationModalCancel}
            title="Notification Azure"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Table columns={azureAlertColumns} dataSource={azureAlertData} />
          </Modal>
        </div>
      </div>
    </div>
  );
};
