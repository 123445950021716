import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Tooltip,
  Popconfirm,
  Button,
  Modal,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";
import AddAzureBudgetAlert from "./AddAzureBudgetAlert";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons/lib/icons";
import EditAzureBudget from "./EditAzureBudget";

const { Option } = Select;
let initialAccountId;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;
let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}
const monthFormat = "YYYY-MM";

export default function AzureBudgetAlert() {
  const [data, setData] = useState([]);
  const [azureAccount, setAzureAccount] = useState("");
  const [azureAccountId, setAzureAccountId] = useState("");
  const [azureAccounts, setAzureAccounts] = useState([]);
  const [month, setMonth] = useState(initalMonth);
  const [budgetAlert, setBudgetAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editRecord, setEditRecord] = useState("");
  const [emails, setEmails] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  var initialProjectName = [];

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Time Grain",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.timeGrain;
      },
    },
    {
      title: "Max Amount",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.amount;
      },
    },
    {
      title: "Current Usage",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return record.properties.currentSpend.amount.toFixed(2);
      },
    },
    {
      title: "Currency",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return record.properties.currentSpend.unit;
      },
    },
    {
      title: "Threshold",
      key: "",
      dataIndex: "",
      align: "center",
      render: (record) => {
        return Object.values(record.properties.notifications).map((value) => {
          return <div>{value.threshold}%</div>;
        });
      },
    },
    {
      title: "Emails",
      key: "",
      dataIndex: "",
      align: "left",
      render: (record) => {
        return Object.values(record.properties.notifications).map(
          (value, index) => {
            if (index === 0) {
              return value.contactEmails.map((email) => {
                return <div>{email}</div>;
              });
            }
          }
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (record) => (
        <Row>
          <Col>
            <Popconfirm
              title="Are you sure to delete this Budget?"
              onConfirm={() => {
                deleteBudgetAlert(record);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Alert">
                <Button type="link" size="small">
                  <DeleteOutlined
                    loading={deleteLoading}
                    style={{ color: "red" }}
                  />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Col>
          <Col>
            <Tooltip title="Edit Alert">
              <Button type="link" size="small">
                <EditOutlined
                  style={{ color: "#5046e5" }}
                  onClick={() => showModal(record)}
                />
              </Button>
            </Tooltip>
            <Modal
              width={800}
              open={visible}
              onCancel={handleModalCancel}
              title="Edit ALert"
              style={{ top: 75 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <EditAzureBudget
                editRecord={editRecord}
                azureAccount={azureAccount}
                azureAccountId={azureAccountId}
                getBudgetAlert={getBudgetAlert}
                handleModalCancel={handleModalCancel}
                data={data}
                emails={emails}
              />
            </Modal>
          </Col>
        </Row>
      ),
    },
  ];

  //Delete Budget Alert
  const deleteBudgetAlert = async (data) => {
    setDeleteLoading(true);
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let name = data.name;
    let response = await axios.delete(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets?budgetName=${name}&accountId=${azureAccountId}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    message.success("Alert Deleted SuccessFully!");
    setDeleteLoading(false);
    getBudgetAlert(azureAccountId, accessToken);
  };

  //Handle Modal Cancel
  const handleModalCancel = () => {
    setVisible(false);
    setData([]);
    setEmails([]);
    setEditRecord("");
  };

  //Show Modal
  const showModal = (record) => {
    setVisible(true);
    setEditRecord(record);
    Object.values(record.properties.notifications).map((value) => {
      let newValue = {};
      let newEmails = {};
      newEmails.contactEmails = value.contactEmails;
      newValue.threshold = value.threshold;
      emails.push(newEmails);
      data.push(newValue);
    });
  };

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0]?.account_name;
      setAzureAccount(initialProjectName);
      initialAccountId = initialAccount[0]?.account_id;
      setAzureAccountId(initialAccountId);
      setAzureAccounts(resp.data);
      setLoading(true);
      getBudgetAlert(initialAccountId, accessToken);
    })();
  }, []);

  //Get Budget Alert
  const getBudgetAlert = async (accountId, accessToken) => {
    setLoading(true);
    let response = await axios.get(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets?accountId=${accountId}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    setBudgetAlert(response.data.value);
    setLoading(false);
  };

  //Handel Azure Project Change
  const handleAzureProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    let filteredDataAzure = azureAccounts.filter((account) => {
      if (value === account.account_id) {
        setAzureAccount(account.account_name);
      }
    });
    setAzureAccountId(value);
    setLoading(true);
    getBudgetAlert(value, accessToken);
  };

  let accountsOptions = azureAccounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  return (
    <div className="my-4 cloud-charts">
      <div className="card">
        <div className="card-body p-5" style={{ height: "470px" }}>
          <div className="">
            <Row>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <h2 className="m-b-20">Azure Budget Alert</h2>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div className="float-end">
                  <AddAzureBudgetAlert
                    accountId={azureAccountId}
                    accountName={azureAccount}
                    getBudgetAlert={getBudgetAlert}
                    budgetAlert={budgetAlert}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <Select
                  value={azureAccount}
                  mode="azureAccount"
                  className="selectProjectGCP"
                  placeholder="Select Account"
                  onChange={handleAzureProjectChange}
                  showArrow
                  clearIcon
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {accountsOptions}
                </Select>
              </Col>
            </Row>
          </div>
          <Table
            className="tableaws"
            columns={columns}
            dataSource={budgetAlert}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
