import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Row,
  Col,
  Empty,
  Tooltip,
  Button,
  Spin,
  Table,
  Input,
  Space,
} from "antd";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import {
  FileExcelOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { exportFileType } from "../../../../util/constants";
import axios from "axios";
import { Auth } from "aws-amplify";
import moment from "moment";

const { Option } = Select;
var initialProjectName = "";

export const GetBillingsByAccountTable = ({ Currency }) => {
  const myRef = useRef(null);
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              handleSearch(selectedKeys, confirm, dataIndex);
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Service Name",
      dataIndex: "servicename",
      fixed: "left",
      width: "15%",
      sortDirections: ["ascend"],
      sorter: (a, b) => a.servicename - b.servicename,
      ...getColumnSearchProps("servicename"),
    },
    {
      title: "Service Total",
      dataIndex: "totalCost",
      fixed: "left",
      render: (text, record) => {
        let total = 0;
        for (const key in record) {
          if (key !== "servicename" && key !== "totalCost") {
            total += record[key];
          }
        }
        total = total.toFixed(2);
        Currency === "USD" ? (total = `$${total}`) : (total = `₹${total}`);
        return <span>{total}</span>;
      },
    },
  ];

  const lastSixMonths = [...Array(12)].map((_, i) =>
    moment().subtract(i, "months").format("YYYY-MM")
  );

  lastSixMonths.forEach((month) => {
    columns.push({
      title: month,
      dataIndex: month,
      render: (text, record) => {
        return record[month]
          ? Currency === "USD"
            ? `$${record[month].toFixed(2)}`
            : `₹${record[month].toFixed(2)}`
          : Currency === "USD"
          ? "$0.00"
          : "₹0.00";
      },
    });
  });

  const [data, setData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState();
  const [project, setprojectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setCurrency(Currency);
      setData([]);
      getRoles(accessToken);
    })();
  }, [Currency]);

  const getRoles = async (accessToken) => {
    let initialAccount = [];
    setLoading(true);
    await fetch(
      `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/allaccountid`,
      { headers: { Authorization: "Bearer " + accessToken } }
    )
      .then((response) => response.json())
      .then((json) => {
        initialAccount = json;
        initialProjectName = initialAccount[0].account_name;
        setprojectName(initialProjectName);
        let initialAccountId = initialAccount[0].account_id;
        setAccountId(initialAccountId);
        setCurrency(Currency);
        asyncFetch(initialAccountId, Currency, accessToken);
        setAccounts(json);
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const asyncFetch = async (accountId, crr, accessToken) => {
    try {
      setLoading(true);
      let response = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/accountscost?account=${accountId}&lastThreeMonthData=true&currency=${crr}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (response) {
        setLoading(false);
      }
      const filteredData = response.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);

      // logic for generate new response to table

      let services = [];

      filteredData.forEach((data) => {
        services.push(data.servicename);
      });

      const s = new Set(services);

      let responseForTable = [];
      for (let servicename of s) {
        let m1 = {};
        for (let res of filteredData) {
          if (servicename === res.servicename) {
            m1[res.month] = res.cost;
          }
        }
        m1.servicename = servicename;
        responseForTable.push(m1);
      }

      const tableData = responseForTable.map((item) => {
        const tableItem = {
          servicename: item.servicename,
          totalCost: null,
        };

        lastSixMonths.forEach((month) => {
          tableItem[month] = item[month] ? item[month] : 0;

          tableItem.totalCost += item[month] ? item[month] : 0;
        });

        return tableItem;
      });

      const dataForTable = tableData;

      setTableData(dataForTable);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };
  const handleChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    setLoading(true);
    setData([]);
    asyncFetch(value, currency, accessToken);
    setAccountId(value);
  };

  let accountsOptions = accounts.map((account) => {
    return (
      <Option name={account.account_name} value={account.account_id}>
        {account.account_name}
      </Option>
    );
  });

  const headers = [
    { label: "Service Name", key: "serviceName", fixed: "left" },
    { label: "Cost", key: "cost" },
    { label: "Month", key: "month" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">Services Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">Account Wise</h5>
            </div>

            {project && (
              <Row gutter={[8, 8]} className="my-2">
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    defaultValue={project}
                    showSearch
                    placeholder="Select Account"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={handleChange}
                  >
                    {accountsOptions}
                  </Select>
                </Col>

                <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                  <CSVLink
                    className="float-end btn-dwn"
                    data={data}
                    headers={headers}
                    filename={
                      exportFileType.AWS_TYPE + "Service Billings_AccWise"
                    }
                  >
                    <Tooltip title="Export to csv">
                      <div className="download-btn float-end aws-sb-btn">
                        <Button size="large" type="text">
                          <FileExcelOutlined />
                        </Button>
                      </div>
                    </Tooltip>
                  </CSVLink>
                </Col>
              </Row>
            )}
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return (
                  <Table
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    style={{ scrollbarWidth: "thin" }}
                    scroll={{
                      x: 1500,
                      y: 300,
                    }}
                  />
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
