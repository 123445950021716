import React, { Component } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  AmazonOutlined,
  GoogleOutlined,
  LayoutFilled,
  CloudFilled,
  WindowsFilled,
  TagFilled,
  UserOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { routes, brand } from "../../util/constants";
// import logo from "../../assests/img/LOGO.png";
import logo from "../../assests/img/cloud.png";
const { SubMenu } = Menu;
let SubMenukey = "";
class SiderMenu extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        className={
          this.props.collapsed ? "sider-menu" : "sider-menu menu-position"
        }
      >
        <div className="side-header">
          <div className="brand-name">
            <div className="brand-logo">
              <div>
                <img className="logo" src={logo} alt="Logo" />
              </div>
              <span className="app-name" mode="full">
                {brand.NAME}
              </span>
            </div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          <Menu.Item key="/dashboard" icon={<LayoutFilled />}>
            <NavLink className="sider-links " to="/dashboard">
              Dashboard
            </NavLink>
          </Menu.Item>
          <p className="menu-subhead" mode="full">
            ANALYTICS
          </p>
          <Menu.Item
            key={routes.AWS_BILLINGS}
            icon={<AmazonOutlined />}
            title="Amazon Web Services"
          >
            <NavLink className="sider-links " to={routes.AWS_BILLINGS}>
              Amazon Web Services
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.AZURE_BILLINGS}
            icon={<WindowsFilled />}
            title="Microsoft Azure"
          >
            <NavLink className="sider-links " to={routes.AZURE_BILLINGS}>
              Microsoft Azure
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.GCP_BILLINGS}
            icon={<GoogleOutlined />}
            title="Google Cloud Platform"
          >
            <NavLink className="sider-links " to={routes.GCP_BILLINGS}>
              Google Cloud Platform
            </NavLink>
          </Menu.Item>

          {/* <Menu.Item
            key={routes.USER_LIST}
            icon={<UserOutlined />}
            title="User"
          >
            <NavLink className="sider-links " to={routes.USER_LIST}>
              Users
            </NavLink>
          </Menu.Item> */}
          <p className="menu-subhead" mode="full">
            TABLE
          </p>
          <Menu.Item
            key={routes.AWS_DATA_TABLE}
            icon={<AmazonOutlined />}
            title="Amazon Web Services"
          >
            <NavLink className="sider-links " to={routes.AWS_DATA_TABLE}>
              Amazon Web Services
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.AZURE_DATA_TABLE}
            icon={<WindowsFilled />}
            title="Microsoft Azure"
          >
            <NavLink className="sider-links " to={routes.AZURE_DATA_TABLE}>
              Microsoft Azure
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.GCP_DATA_TABLE}
            icon={<GoogleOutlined />}
            title="Google Cloud Platform"
          >
            <NavLink className="sider-links " to={routes.GCP_DATA_TABLE}>
              Google Cloud Platform
            </NavLink>
          </Menu.Item>
          <p className="menu-subhead" mode="full">
            SETTINGS
          </p>
          <Menu.Item
            key={routes.TAG_MANAGEMENT}
            icon={<TagFilled />}
            title="Resource Cost & Tags"
          >
            <NavLink className="sider-links " to={routes.TAG_MANAGEMENT}>
              Resource Cost & Tags
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.CLOUD_LIMIT}
            icon={<CloudFilled />}
            title="Daily Cloud Limit Alert"
          >
            <NavLink className="sider-links " to={routes.CLOUD_LIMIT}>
              Daily Cloud Limit Alert
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key={routes.BUDGET_ALERT}
            icon={<AlertOutlined />}
            title="Budget Alert"
          >
            <NavLink className="sider-links " to={routes.BUDGET_ALERT}>
              Budget Alert{" "}
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  location: PropTypes.object.isRequired,
});
export default connect(mapStateToProps, null)(SiderMenu);
