import React, { useState, useEffect, useRef } from "react";
import { Empty, Tooltip, Button, Col, Row, Spin } from "antd";
import { Line } from "@ant-design/plots";
import { CSVLink } from "react-csv";
import { LoadingOutlined } from "@ant-design/icons";
import { exportFileType } from "../../../../../util/constants";
import axios from "axios";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons/lib/icons";

export const GetAllBillings = ({ Currency }) => {
  const myRef = useRef(null);
  const printDocument = () => {
    html2canvas(myRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 5, 50, 200, 150);
      pdf.save("download.pdf");
    });
  };

  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setCurrency(Currency);
      setLoading(true);
      setData([]);
      asyncFetch(Currency, accessToken);
    })();
  }, [Currency]);

  //Fetch Data
  const asyncFetch = async (Currency, accessToken) => {
    try {
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/aws/accountscost?account=null&lastThreeMonthData=true&currency=${Currency}`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      if (resp.data.data) {
        setLoading(false);
      }
      const filteredData = resp.data.data.filter((filterData) => {
        if (filterData.cost > 0) return filterData;
      });
      setData(filteredData);
    } catch (error) {
      console.log("fetch data failed", error);
    }
  };

  const config = {
    data,
    xField: "MONTH",
    yField: "cost",
    seriesField: "accountname",

    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  const headers = [
    {
      label: "Account Id",
      key: "accountid",
    },
    { label: "Name", key: "accountname" },
    { label: "Cost", key: "cost" },
    { label: "Month", key: "MONTH" },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );

  return (
    <div id="divToPrint" ref={myRef}>
      <div className="my-4 cloud-charts">
        <div className="card" style={{ height: "500px" }}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <h3 className="text-secondary  mb-3">All Accounts Billings</h3>
              <h5 className="text-secondary  mt-2 ms-3">Monthly</h5>
            </div>
            <Row gutter={[8, 8]} className="my-2">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CSVLink
                  className="float-end btn-dwn"
                  data={data}
                  headers={headers}
                  filename={
                    exportFileType.AWS_TYPE + "Accounts Billings_Monthly"
                  }
                >
                  <Tooltip title="Export to csv">
                    <div className="download-btn">
                      <Button size="large" type="text">
                        <FileExcelOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </CSVLink>
                <Tooltip title="Export to PDF">
                  <div className="download-btn float-end aws-sb-btn3">
                    <Button size="large" type="text" onClick={printDocument}>
                      <FilePdfOutlined />
                    </Button>
                  </div>
                </Tooltip>
              </Col>
            </Row>
            {loading && (
              <div className="mt-5 d-grid">
                <Spin indicator={antIcon} />
                <p className="d-flex justify-content-center text-secondary">
                  Loading...
                </p>
              </div>
            )}
            {(() => {
              if (data.length === 0 && !loading) {
                return (
                  <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 100,
                    }}
                  />
                );
              } else if (data.length !== 0) {
                return <Line {...config} />;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
