import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Select,
  Button,
  Form,
  Input,
  Tooltip,
  DatePicker,
  message,
  Space,
  Tag,
} from "antd";
import moment from "moment";
import { Auth } from "aws-amplify";
import axios from "axios";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
let initialAccountId;
var initialProjectName = [];
const { Option } = Select;
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth;

let checkStartOfMonth = moment().format("D");
if (checkStartOfMonth === 1) {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
} else {
  if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
  else initalMonth = defaultYear + "0" + defaultMonth;
}

export default class AddAzureBudgetAlert extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      azureRecord: "",
      budgetName: "",
      budgetsPercent: [],
      start: "",
      end: "",
      month: initalMonth,
      items: [],
      value: "",
      value2: "",
      emailError: null,
      errors: {},
      azureAccountId: "",
      azureAccount: "",
      azureAccounts: [],
      timeGrain: "Monthly",
      index: "",
      indexNumber: {},
      newBudget: [],
    };
    this.onFinish = this.onFinish.bind(this);
  }
  initialProjectName = [];

  componentDidMount() {
    (async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      let initialAccount = [];
      let resp = await axios.get(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/accountscost?monthYear=${this.state.month}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      initialAccount = resp.data;
      initialProjectName = initialAccount[0]?.account_name;
      this.setState({ azureAccount: initialProjectName });
      initialAccountId = initialAccount[0]?.account_id;
      this.setState({ azureAccountId: initialAccountId });
      this.setState({ azureAccounts: resp.data });
    })();
  }

  //Azure Project Change
  handleAzureProjectChange = async (value) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let filteredDataAzure = this.state.azureAccounts.filter((account) => {
      if (value === account.account_id) {
        this.setState({ azureAccount: account.account_name });
      }
    });
    this.setState({ azureAccountId: value });
  };

  //Get Budget Alert
  getBudgetAlert = () => {
    this.props.getBudgetAlert(this.state.azureAccountId);
  };

  //Show Modal
  showModal = () => {
    this.setState({
      azureAccountId: this.props.accountId,
      visible: true,
    });
  };

  //Modal Cancel
  handleModalCancel = () => {
    this.setState({ visible: false, items: [], loading: false });
  };

  //Handle Change Date
  handleChangeDate = (range) => {
    const valueOfInput1 = range[0].format("YYYY-MM-DD");
    const valueOfInput2 = range[1].format("YYYY-MM-DD");
    String.prototype.replaceAt = function (index, replacement) {
      if (index >= this.length) {
        return this.valueOf();
      }
      var chars = this.split("");
      chars[index] = replacement;
      return chars.join("");
    };
    var str = valueOfInput1;
    str = str.replaceAt(8, "0");
    str = str.replaceAt(9, "1");

    var str2 = valueOfInput2;
    str2 = str2.replaceAt(8, "3");
    str2 = str2.replaceAt(9, "0");

    this.setState({ start: str, end: str2 });
  };

  //Handle Change Percentage
  handleChangePercent = (evt, index) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
    const amountPercentage = (this.state.limit / 100) * evt.target.value;
    this.setState({ amount: amountPercentage });
    let tempArray = this.state.indexNumber;
    tempArray[index] = amountPercentage;
    this.setState({ indexNumber: tempArray });
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
      emailError: null,
    });
  };

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = this.state.value.trim();
      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
        });
      }
    }
  };

  //Handle Email Delete
  handleDelete = (item) => {
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  //Handle Email Paste
  handlePaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));
      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  isValid(email) {
    let emailError = null;
    if (this.isInList(email)) {
      emailError = `${email} has already been added.`;
    }
    if (!this.isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }
    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  //On Submit Form
  async onFinish(event) {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    this.setState({ loading: true });
    {
      event.budgetsPercent.map((percent) => {
        this.state.newBudget.push(percent.budgetPercentage);
      });
    }
    let flag = 1;
    this.props.budgetAlert?.forEach((row) => {
      if (row.name === this.state.budgetName) {
        flag = 0;
      }
    });
    if (flag === 0) {
      message.error("Budget Name Already Exist!");
      this.setState({ loading: false });
    } else {
      let obj = {
        queryStringParameters: {
          accountId: this.state.azureAccountId,
          budgetName: this.state.budgetName,
          startDate: this.state.start,
          endDate: this.state.end,
          timeGrain: this.state.timeGrain,
          budgetAmount: this.state.limit,
          notificationsEmails: this.state.items,
          budgetsPercent: this.state.newBudget,
        },
      };
      let response = await axios.put(
        `https://ip8lmm5ecg.execute-api.us-east-1.amazonaws.com/prod/azure/budgets`,
        obj,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      this.setState({ loading: false, visible: false });
      if (response.data.statusCode === 200) {
        message.success("Azure Alert Created SuccessFully!");
      }
      this.getBudgetAlert(accessToken);
    }
  }

  //Handle Timegrain Change
  handleTimeGrainChange = (value) => {
    this.setState({ timeGrain: value });
  };

  render(
    accountsOptions = this.state.azureAccounts.map((account) => {
      return (
        <Option name={account.account_name} value={account.account_id}>
          {account.account_name}
        </Option>
      );
    })
  ) {
    const { visible, loading } = this.state;
    const { props } = this.props;
    return (
      <span>
        <div>
          <>
            <Tooltip>
              <Button onClick={this.showModal} className="float-end">
                Add Budget Alert
              </Button>
            </Tooltip>
          </>
          <Modal
            width={800}
            open={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Add Alert Azure"
            style={{ top: 75 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              ref={this.formRef}
              name="add-SBU"
              className="add-SBU"
              onFinish={this.onFinish}
              initialValues={{
                remember: true,
                budgetsPercent: [""],
              }}
            >
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Select Account",
                      },
                    ]}
                  >
                    <Select
                      value={this.state.azureAccount}
                      mode="azureAccount"
                      placeholder="Select Account"
                      onChange={this.handleAzureProjectChange}
                      showArrow
                      clearIcon
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {accountsOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item>
                    <Select
                      defaultValue="Monthly"
                      onChange={this.handleTimeGrainChange}
                      options={[
                        {
                          value: "Monthly",
                          label: "Monthly",
                        },
                        {
                          value: "Quarterly",
                          label: "Quarterly",
                        },
                        {
                          value: "Annually",
                          label: "Annually",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="budgetName"
                    rules={[
                      {
                        required: true,
                        message: "Please fill budget name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Budget Name"
                      onChange={(e) => {
                        this.setState({ budgetName: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="startEndDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select start and end date",
                      },
                    ]}
                  >
                    <RangePicker
                      style={{ width: " -webkit-fill-available" }}
                      format="YYYY-MM"
                      picker="month"
                      name="date"
                      id="date"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={this.handleChangeDate}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="limit"
                    rules={[
                      {
                        required: true,
                        message: "Please add max limit",
                      },
                    ]}
                  >
                    <Input
                      addonBefore="$"
                      placeholder="Max Limit"
                      name="limit"
                      type="number"
                      min="0"
                      step="0.5"
                      onChange={(e) => {
                        this.setState({ limit: parseInt(e.target.value) });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.List name="budgetsPercent">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Row>
                            <Space key={field.key} align="baseline">
                              <Col>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.area !== curValues.area ||
                                    prevValues.budgetsPercent !==
                                      curValues.budgetsPercent
                                  }
                                >
                                  {() => (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "budgetPercentage"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please add budget percentage",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Budget Percentage"
                                        addonAfter="%"
                                        name="budgetPercentage"
                                        type="number"
                                        value={this.state.budgetsPercent}
                                        onChange={(event) =>
                                          this.handleChangePercent(event, index)
                                        }
                                      />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                              </Col>
                              <Col>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "amount"]}
                                >
                                  <div>
                                    <Tag
                                      className="tagbudget"
                                      style={{
                                        margin: "0 0px 0 0 ",
                                        padding: "0 8px",
                                        fontSize: "14px ",
                                        lineHeight: "30px ",
                                        width: "138px",
                                      }}
                                    >
                                      Amount - {this.state.indexNumber[index]}
                                    </Tag>
                                  </div>
                                </Form.Item>
                              </Col>

                              <Col>
                                {index === 0 ? null : (
                                  <MinusCircleOutlined
                                    twoToneColor="red"
                                    style={{ fontSize: "18px" }}
                                    onClick={() => {
                                      remove(field.name);
                                      this.onRemove(index);
                                    }}
                                  />
                                )}
                              </Col>
                            </Space>
                          </Row>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusCircleOutlined />}
                          >
                            Add Option
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {this.state.items.map((item) => (
                    <div className="tag-item mb-2" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                  <Input
                    name="value"
                    className={"input" + (this.state.emailError && "has-error")}
                    value={this.state.value}
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                    onPaste={this.handlePaste}
                  />
                  {this.state.emailError && (
                    <p className="error">{this.state.emailError}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="float-end mt-5">
                    <Button
                      className="close-modal me-3"
                      onClick={this.handleModalCancel}
                    >
                      Close
                    </Button>

                    {this.state.items.length !== 0 ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={loading}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled>Save</Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>
      </span>
    );
  }
}
